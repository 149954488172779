import { Auth, Cache } from "aws-amplify"
import cn from "classnames"
import classNames from "classnames"
import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "next/router"
import ChangePartyCategoryModal from "../../party/NewPartyModal/ChangePartyCategoryModal"
import CreatePartyModal from "../../party/NewPartyModal/CreatePartyModal"
import LoadingParty from "../../party/NewPartyModal/LoadingParty"
import SelectPartyServices from "../../party/NewPartyModal/SelectPartyServices"
import SelectStyle from "../../party/NewPartyModal/SelectStyle"
import * as styles from "./AuthenticationModal.module.scss"
import ForgotPassword from "./ForgotPassword"
import ForgotPasswordVerify from "./ForgotPasswordVerify"
import Login from "./Login"
import LimitReached from "./LimitReached"
import SignUp from "./SignUp"
import SignUpThanks from "./SignUpThanks"
import { eventGtag } from "@lib/gtag"
import hashCode from "@lib/hashCode"
import trackFBEvent from "@lib/trackFBEvent"
import fetchLambda from "@data/fetchLambda"
import lambdas from "@data/lambdas"
import * as actions from "@store/actions"
import ReactModal from "@components/global/ReactModal/ReactModal"

const { PARTY_SUBMIT, PARTY_SUPPLIER_SUBMIT, SUPPLIER_SEARCH } = lambdas[process.env.NEXT_PUBLIC_ENV]

class AuthenticationModal extends Component {
  state = {
    step: this.props.defaultStep === "createParty" && !this.props.search.category ? "category" : this.props.defaultStep,
    modalIsOpen: false,
    firstName: "",
    email: "",
    password: "",
    passwordConfirm: "",
    hasPartyToCreate: false,
    party: {
      partyName: "",
      partyLocation: "",
      partyDate: "0",
      partyStatus: 1,
      partyServices: [],
      partyStyle: "",
    },
  }

  openModal = () => {
    this.setState({
      modalIsOpen: true,
      currpartyCategory: this.props.search.category,
      step:
        this.props.defaultStep === "createParty" && !this.props.search.category ? "category" : this.props.defaultStep,
    })
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false })
    this.setState({
      step:
        this.props.defaultStep === "createParty" && !this.props.search.category ? "category" : this.props.defaultStep,
    })
  }

  changeModal = (step) => {
    this.setState({ step: step })
  }

  updateState = (state) => {
    this.setState(state)
  }

  handleChange = (event) => {
    let target = event.target
    let value
    let name

    value = target.type === "checkbox" ? target.checked : target.value
    name = target.name

    const newParty = { ...this.state.party }

    newParty[name] = value

    this.setState({
      party: newParty,
    })
  }

  //Multiselect input
  handleChangeSelect = (selected, type) => {
    const newParty = { ...this.state.party }

    newParty[type.name] = selected.value

    this.setState({
      party: newParty,
    })
  }

  handleChangeLocation = (newParty) => {
    let formatParty = { ...newParty }
    if (formatParty.location) {
      formatParty.partyLocation = JSON.stringify(formatParty.location)
    } else if (formatParty.partyLocation) {
      delete formatParty.partyLocation
    }
    this.setState({
      party: formatParty,
    })
  }

  handleChangeParty = (name, value) => {
    this.setState((prevState) => ({
      party: {
        ...prevState.party,
        [name]: value,
      },
    }))

    if (name === "partyStyle") {
      this.setState({
        step: "createparty",
      })
    }
  }

  handleUpdatepartyServices = (value, append) => {
    this.setState((prevState) => {
      let partyServices = prevState.party.partyServices
      if (append) {
        partyServices = [...partyServices, value]
      } else {
        partyServices = partyServices.filter((el) => el !== value)
      }
      return {
        party: {
          ...prevState.party,
          partyServices,
        },
      }
    })
  }

  createParty = async (newparty) => {
    this.setState({ step: "loading" })

    const user = await Auth.currentSession()

    //create party
    const partyResponse = await fetchLambda(PARTY_SUBMIT, {
      sessionToken: user.getIdToken().getJwtToken(),
      data: newparty,
    })
    const party = partyResponse.data.party

    //analytics
    const { categoryOptions } = this.props.staticData
    const categoryData = categoryOptions.find((category) => category.value === party.partyCategory)
    eventGtag({
      action: "Create",
      params: {
        event_category: "Party",
        event_label: categoryData ? categoryData.label : "",
        value: party.partyId,
      },
    })

    if (party && this.props.toggleSaveToParty) {
      await this.props.toggleSaveToParty(party)
    }

    // if not saving supplier pre populate party
    // get list of suppliers based off services
    if (newparty.partyServices && newparty.partyServices.length && !this.props.toggleSaveToParty) {
      for (const service of newparty.partyServices) {
        const supplierSearch = await fetchLambda(SUPPLIER_SEARCH, {
          params: {
            first: 2,
            orderBy: "rank",
            order: "DESC",
            skip: 0,
            category: newparty.partyCategory,
            services: [service],
            style: newparty.partyStyle,
            //todo add location
            // "location",
          },
        })

        const generatedSuppliers = supplierSearch.data.result.allSuppliers

        //add suppliers to party
        if (generatedSuppliers && generatedSuppliers.length) {
          this.props.partyGeneratedSuppliersChange(true)
          for (const supplier of generatedSuppliers) {
            const partySupplierResponse = await fetchLambda(PARTY_SUPPLIER_SUBMIT, {
              sessionToken: user.getIdToken().getJwtToken(),
              data: {
                createdAt: party.partyId,
                supplierId: supplier.id,
                isAdding: true,
              },
            })
          }
        }
      }
    }

    this.props.partyUpdate(party)
    this.props.router.push(`/party/${party.partyId}`)
  }

  handleCreateParty = async (party, hasAccount = false) => {
    const newParty = party ? party : this.state.party
    if (hasAccount) {
      await this.createParty(newParty)
    } else {
      this.setState({
        step: "signup",
        party: newParty,
        hasPartyToCreate: true,
      })
    }
  }

  cacheNewParty = () => {
    const newPartyKey = hashCode("hoppNewParty")
    Cache.setItem(newPartyKey, this.state.party)

    const newSupplierKey = hashCode("hoppNewSupplier")
    Cache.setItem(newSupplierKey, this.props.supplierID)
  }

  socialSignIn = (provider) => {
    // Social registration is the same as login as we currently don't have a way of knowing when the cognito account was created.
    eventGtag({
      action: "Sent",
      params: {
        event_category: "Register",
        event_label: provider,
      },
    })

    trackFBEvent("CompleteRegistration")

    try {
      if (this.state.hasPartyToCreate) {
        this.cacheNewParty()
        this.props.socialLogin(provider, "signInCreateParty")
      } else {
        this.props.socialLogin(provider)
      }
    } catch (e) {
      alert(e.message)
    }
  }

  //openOnLoad
  componentDidUpdate = (prevProps) => {
    if (this.props.openOnLoad !== prevProps.openOnLoad) {
      if (this.props.openOnLoad) {
        setTimeout(() => {
          this.setState({
            modalIsOpen: true,
          })
        }, 1000)
      }
    }
  }

  componentDidMount = () => {
    if (this.props.openOnLoad) {
      setTimeout(() => {
        this.setState({
          modalIsOpen: true,
        })
      }, 1000)
    }
  }

  getModalSize = () => {
    switch (this.state.step) {
      case "category":
      case "services":
      case "style":
      case "limitReached":
      case "thanksBig":
        return styles.largeModal
      default:
        return ""
    }
  }

  render() {
    return (
      <div className={styles.AuthenticationModal}>
        <ReactModal
          modalIsOpen={this.state.modalIsOpen}
          openModal={this.openModal}
          onRequestClose={() => !this.props.isLocked && this.closeModal()}
          button={this.props.button}
          modalLabel="Signup"
          isLocked={this.props.isLocked}
          className={this.getModalSize()}
        >
          <div className={styles.inner}>
            {(() => {
              switch (this.state.step) {
                case "createparty":
                  return (
                    <CreatePartyModal
                      changeModal={this.changeModal}
                      party={this.state.party}
                      partyCategory={
                        this.state.party && this.state.party.partyCategory
                          ? this.state.party.partyCategory
                          : this.props.search.category
                      }
                      handleCreateParty={this.handleCreateParty}
                      handleChange={this.handleChange}
                      handleChangeSelect={this.handleChangeSelect}
                      handleChangeParty={this.handleChangeParty}
                      handleChangeLocation={this.handleChangeLocation}
                    />
                  )
                case "category":
                  return (
                    <ChangePartyCategoryModal
                      changeModal={this.changeModal}
                      handleChangeParty={this.handleChangeParty}
                    />
                  )
                case "services":
                  return (
                    <SelectPartyServices
                      changeModal={this.changeModal}
                      partyServices={this.state.party.partyServices}
                      handleUpdatepartyServices={this.handleUpdatepartyServices}
                    />
                  )
                case "style":
                  return (
                    <SelectStyle
                      changeModal={this.changeModal}
                      partyStyle={this.state.party.partyStyle}
                      handleChangeParty={this.handleChangeParty}
                    />
                  )
                case "loading":
                  return (
                    <LoadingParty
                      title={`Creating your party`}
                      text="Finding the perfect suppliers"
                      callback={() => true}
                    />
                  )

                case "signup":
                  return (
                    <SignUp
                      changeModal={this.changeModal}
                      socialSignIn={this.socialSignIn}
                      updateState={this.updateState}
                      hasPartyToCreate={this.state.hasPartyToCreate}
                      handleCreateParty={this.handleCreateParty}
                    />
                  )
                case "thanks":
                  return <SignUpThanks firstName={this.state.firstName} closeModal={this.closeModal} />
                case "thanksBig":
                  return <SignUpThanks firstName={this.state.firstName} closeModal={this.closeModal} />
                case "login":
                  return (
                    <Login
                      closeModal={this.closeModal}
                      hasPartyToCreate={this.state.hasPartyToCreate}
                      handleCreateParty={this.handleCreateParty}
                      changeModal={this.changeModal}
                      backStage={this.props.defaultStep === "limitReached" ? "limitReached" : "signup"}
                    />
                  )
                case "forgot":
                  return <ForgotPassword changeModal={this.changeModal} />
                case "forgotVerify":
                  return <ForgotPasswordVerify changeModal={this.changeModal} resetCode={this.props.resetCode} />
                case "limitReached":
                  return (
                    // <SignUp
                    // title="GET FREE ACCESS TO THE HOPP EDIT"
                    //   description="You have reached the view limit. To continue browsing, please sign up to get free access to HOPP’s curated edit of the best Party Professionals and rewards in the luxury events industry. Every 100th person to sign up gets a chance to win our HOPP Pink Marmalade Gin!"
                    //   changeModal={this.changeModal}
                    //   socialSignIn={this.socialSignIn}
                    //   updateState={this.updateState}
                    //   hasPartyToCreate={this.state.hasPartyToCreate}
                    //   handleCreateParty={this.handleCreateParty}
                    // />
                    <LimitReached changeModal={this.changeModal} updateState={this.updateState} />
                  )
                default:
                  return null
              }
            })()}
          </div>
        </ReactModal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    search: state.search.parameters,
    currentParty: state.party.currentParty,
    staticData: state.staticData.data,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    partyUpdate: (party) => dispatch(actions.partyUpdate(party)),
    partyGeneratedSuppliersChange: (hasGeneratedSuppliers) =>
      dispatch(actions.partyGeneratedSuppliersChange(hasGeneratedSuppliers)),
    socialLogin: (provider, customState) => dispatch(actions.socialLogin(provider, customState)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthenticationModal))
