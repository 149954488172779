import React, { useState } from "react"
import { connect } from "react-redux"
import SiteWrapper from "../../structure/SiteWrapper/SiteWrapper"
import Menu from "../Menu/Menu"
import SiteLogo from "../SiteLogo/SiteLogo"
import Hamburger from "./Hamburger.js"
import * as styles from "./Masthead.module.scss"
import useMediaQuery from "@lib/useMediaQuery"


const Masthead = (props) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false)
  const [accountMenuOpen, setAccountMenuOpen] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const isMobile = useMediaQuery(1099)

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen)
  }

  return (
    <>
      <header onMouseLeave={() => setSubMenuOpen(false)} className={styles.Masthead}>
        <SiteWrapper full>
          <div className={styles.inner}>
            <SiteLogo className={styles.logo} />
            {isMobile && <Hamburger type="spin" active={mobileMenuOpen} clickHandler={() => toggleMobileMenu()} />}
            <div className={`${styles.menu} ${mobileMenuOpen && styles.isActive}`}>
              <Menu
                history={props.history}
                isAuthenticated={props.isAuthenticated}
                userAttributes={props.userAttributes}
                userGroups={props.userGroups}
                accountMenuOpen={accountMenuOpen}
                openAccountMenu={() => setAccountMenuOpen(true)}
                closeAccountMenu={() => setAccountMenuOpen(false)}
                subMenuOpen={subMenuOpen}
                openSubMenu={() => setSubMenuOpen(true)}
                closeSubMenu={() => setSubMenuOpen(false)}
                searchParams={props.searchParams}
              />
            </div>
          </div>
        </SiteWrapper>
      </header>
      <div className={styles.spacer}></div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    userAttributes: state.auth.userAttributes,
    userGroups: state.auth.userGroups,
    searchParams: state.search.parameters,
  }
}

export default connect(mapStateToProps)(Masthead)
