/**
 * Fragment Queries
 *
 * These queries are used in other queries in this file. These are not exported.
 *
 */
import { queries } from "./datocms"

const metaTags = `
  attributes
  content
  tag
`

const responsiveImage = `
  srcSet
  webpSrcSet
  sizes
  src
  width
  height
  aspectRatio
  alt
  title
  base64
`

export const newsletter = `
global {
  newsletterTitle
  newsletterDescription
  newsletterImage {
    url
    responsiveImage(imgixParams: { fit: crop, w: 900, h: 1400, auto: format}) {
      ${responsiveImage}
    }
  }
}
`

/**
 * Archive Queries
 *
 * These queries are used to fetch mulitple records from a single model. Used in archive pages.
 * The name of the object is relative to the model slug. Reffference to these are found in ./static.js
 */

export const supplier = {
  query: `
  id        
  slug
  name
  addresscity
  addresspostcode
  addressstreet1
  addressstreet2
  contactname
  email
  phone
  website
  bundle
  overwriteLocation
  overwriteService
  stripeId
  stripeStatus
  stripeTrialEnd
  createdby
  offer
  offerother
  hoppRecommended
  internalnote
  coverphoto {
    responsiveImage(imgixParams: { fit: crop, w: 400, h: 300, auto: format, dpr: 2}) {
      ${responsiveImage}
    }
  }
  photos {
    photo {
      responsiveImage(imgixParams: { fit: crop, w: 400, h: 300, auto: format, dpr: 2}) {
        ${responsiveImage}
      }
    }
  }
  supplierservices {
    id
    service
  }
  suppliercategory {
    id
    category
    virtual
  }
  pricerange     
`,
  defaultOrder: "rank_DESC",
}

export const productBrand = {
  query: `
    value: id
    label: title
  `,
  defaultOrder: "title_ASC",
}

export const productEdit = {
  query: `
  id
  title
  formatTitle
  slug
  featuredImage {
    responsiveImage(imgixParams: { fit: crop, w: 1000, auto: format}) {
      ${responsiveImage}
    }
  }
`,
  defaultOrder: "createdAt_DESC",
}

export const product = {
  query: `
    id
    title
    price
    priceUpper
    image
    link
    description
    category {
      title
      id
    }
    brand {
      title
      id
    }
  `,
  defaultOrder: "title_ASC",
}

export const blog = {
  query: `
    id
    slug
    publishedDate
    title
    featuredImage {
      url
      responsiveImage(imgixParams: { fit: crop, w: 800, h: 500, auto: format }) {
        ${responsiveImage}
      }
    }
    author {
      name
    }
  `,
  defaultOrder: "publishedDate_DESC",
}

export const guide = {
  query: `
    id
    slug
    title
    featuredImage {
      url
      responsiveImage(imgixParams: { fit: crop, w: 800, h: 500, auto: format }) {
        ${responsiveImage}
      }
    }
    author {
      name
    }
  `,
  defaultOrder: "createdAt_DESC",
}

export const newsletterPage = {
  query: `
    slug
  `,
  defaultOrder: "title_ASC",
}

export const partyTypePage = {
  query: `
    slug
  `,
  defaultOrder: "title_ASC",
}

export const partyServicePage = {
  query: `
    id
    shortTitle
    slug
    featuredImage {
      url
    }
    service {
      id
      service
      category {
        serviceCategory
      }
      icon {
        url
      }
    }
  `,
  defaultOrder: "shortTitle_ASC",
}

export const infoPage = {
  query: `
    id
    slug
    title
    content
  `,
  defaultOrder: "title_ASC",
}

/**
 * Category Queries
 *
 * These queries are used to fetch option data records.
 * The name of the object is relative to the model slug. Reffference to these are found in ./static.js
 */

export const productCategory = {
  query: ` 
    value: id
    label: title
  `,
  defaultOrder: "title_ASC",
}

export const suppliercategory = {
  query: `
    value: id
    label: category
    virtual
    services {
      id
    }
    icon {
      url
    }
    iconActive {
      url
    }
    tags {
      value: id
      label: tag
    }
  `,
  defaultOrder: "category_ASC",
}

export const suppliercounty = {
  query: `
    value: id
    label: county
    dataRegionid: regionid {
      id
    }
  `,
  defaultOrder: "county_ASC",
}

export const supplierservice = {
  query: `
    value: id
    label: service
    icon {
      url
    }
    iconActive {
      url
    }
  `,
  defaultOrder: "service_ASC",
}

export const Supplierservicecateogry = {
  query: `
    id
    label: serviceCategory
    image {
      url
    }
    options: services {
      value: id,
      label: service
      icon {
        url
      }
      iconActive {
        url
      }
    }
  `,
  defaultOrder: "position_ASC",
}

export const Suppliersubservice = {
  query: `
    value: id
    label: subservice
    dataService: serviceid {
      id
      service
    }
  `,
  defaultOrder: "subservice_ASC",
}

export const supplierstyle = {
  query: `
    value: id
    label: style
    image {
      url
    }
  `,
  defaultOrder: "style_ASC",
}

export const imageTag = {
  query: `
    value: id
    label: tag
  `,
  defaultOrder: "tag_ASC",
}
export const supplierOptions = {
  query: `
    value: id
    label: name
    name
    id
    slug
  `,
  defaultOrder: "name_ASC",
}

/**
 * Inner Queries
 *
 * These queries are used to fetch a single record from a single model. Used in inner pages such as articles.
 *
 */

export const innerBlog = `
 seo: _seoMetaTags {
   ${metaTags}
 }
 id
 slug
 title
 body
 author {
   name
   image {
     responsiveImage(imgixParams: { fit: crop, w: 150, h: 150, auto: format }) {
       ${responsiveImage}
     }
   }
   description
   facebook
   instagram
   twitter
 }
 featuredImage {
   responsiveImage(imgixParams: {fm: jpg, fit: crop, w: 1200, h: 750 }) {
     ${responsiveImage}
   }
 }
 audioFile {
   url
 }
 video {
   provider
   providerUid
   width
   height
   url
   thumbnailUrl
   title
 }
 featuredSuppliers {
   id
   name
   slug
   addresscity
   addresspostcode
   addressstreet1
   addressstreet2
   contactname
   email
   phone
   website
   coverphoto {
     responsiveImage(imgixParams: {fm: jpg, fit: crop, w: 300 }) {
       ${responsiveImage}
     }
   }
   supplierservices {
     id
     service
   }
   pricerange
 }
`

export const innerNewsletterPage = `
  seo: _seoMetaTags {
    ${metaTags}
  }
  id
  slug
  title
  intro
  content
  backgroundImage {
    url
  }
  image {
    responsiveImage(imgixParams: { fit: crop, w: 600, h: 600, auto: format }) {
      ${responsiveImage}
    }
  }
  outro
  checkboxList {
    id
    tag
    label
  }
`
export const innerGuide = `
 seo: _seoMetaTags {
   ${metaTags}
 }
 id
 slug
 title
 body
 author {
   name
   image {
     responsiveImage(imgixParams: { fit: crop, w: 150, h: 150, auto: format }) {
       ${responsiveImage}
     }
   }
   description
   facebook
   instagram
   twitter
 }
 featuredImage {
   responsiveImage(imgixParams: {fm: jpg, fit: crop, w: 1200, h: 750 }) {
     ${responsiveImage}
   }
 }
 audioFile {
   url
 }
 video {
   provider
   providerUid
   width
   height
   url
   thumbnailUrl
   title
 }
 featuredSuppliers {
   id
   name
   slug
   coverphoto {
     responsiveImage(imgixParams: {fm: jpg, fit: crop, w: 300 }) {
       ${responsiveImage}
     }
   }
   supplierservices {
     id
     service
   }
   pricerange
   addresscity
   addresspostcode
   addressstreet1
   addressstreet2
   contactname
   email
   phone
   website
 }
`

export const innerPartyTypePage = `
  seo: _seoMetaTags {
    ${metaTags}
  }
  id
  slug
  title
  shortTitle
  intro
  bodyHeader
  content {
    id
    step
    title
    text
    image {
      url
      responsiveImage(imgixParams: {fit: crop, w: 800, h:600, auto: format}) {
        ${responsiveImage}
      }
    }
  }
  category {
    id
    category
  }
  partyServicePages {
    id
    shortTitle
    slug
    featuredImage {
      url
    }
    service {
      service
      category {
        serviceCategory
      }
      icon {
        url
      }
    }
  }
  featuredImage {
    url
  }
  guidesBackground {
    url
  }
  featuredGuides {
    id
    title
    slug
    featuredImage {
      url
      responsiveImage(imgixParams: {fit: crop, w: 800, h:500, auto: format}) {
        ${responsiveImage}
      }
    }
  }
  featuredPosts {
    id
    slug
    title
    featuredImage {
      url
      responsiveImage(imgixParams: {fit: crop, w: 600, h:375, auto: format}) {
        ${responsiveImage}
      }
    }
  }
  featuredSuppliers {
    id
    slug
    name
    bundle
    overwriteLocation
    overwriteService
    stripeId
    stripeStatus
    stripeTrialEnd
    createdby
    offer
    offerother
    hoppRecommended
    internalnote
    coverphoto {
      url
      responsiveImage(imgixParams: {fit: crop, w: 500, auto: format}) {
        ${responsiveImage}
      }
    }
    supplierservices {
      id
      service
    }
    suppliercategory {
      category
      id
      virtual
    }
    pricerange
    addresscity
    addresspostcode
    addressstreet1
    addressstreet2
    contactname
    email
    phone
    website
  }
`

export const innerPartyServicePage = `
  seo: _seoMetaTags {
    ${metaTags}
  }
  id
  slug
  title
  intro
  bodyHeader
  content {
    id
    step
    title
    text
    image {
      url
      responsiveImage(imgixParams: {fit: crop, w: 800, h:600, auto: format}) {
        ${responsiveImage}
      }
    }
  }
  service {
    id
    service
  }
  category {
    id
    category
  }
  featuredImage {
    responsiveImage(imgixParams: {fit: crop, w: 2000 auto: format}) {
      ${responsiveImage}
    }
  }
  guidesBackground {
    url
  }
  featuredGuides {
    id
    title
    slug
    featuredImage {
      url
      responsiveImage(imgixParams: {fit: crop, w: 800, h:500, auto: format}) {
        ${responsiveImage}
      }
    }
  }
  featuredPosts {
    id
    slug
    title
    featuredImage {
      url
      responsiveImage(imgixParams: {fit: crop, w: 600, h:375, auto: format}) {
        ${responsiveImage}
      }
    }
  }
  featuredSuppliers {
    id
    slug
    name
    bundle
    overwriteLocation
    overwriteService
    stripeId
    stripeStatus
    stripeTrialEnd
    createdby
    offer
    offerother
    hoppRecommended
    internalnote
    addresscity
    addresspostcode
    addressstreet1
    addressstreet2
    contactname
    email
    phone
    website
    coverphoto {
      url
      responsiveImage(imgixParams: {fit: crop, w: 500, auto: format}) {
        ${responsiveImage}
      }
    }
    supplierservices {
      id
      service
    }
    suppliercategory {
      id
      category
      virtual
    }
    pricerange
  }
`

export const innerProductEdit = `
  seo: _seoMetaTags {
    ${metaTags}
  }
  id
  slug
  title
  formatTitle
  description
  featuredImage {
    url
  }
`

export const innerSupplier = `
 seo: _seoMetaTags {
   ${metaTags}
 }
 id
 slug
 name
 description
 coverphoto {
   responsiveImage(imgixParams: { fit: crop, w: 1200, h: 750, auto: format }) {
     ${responsiveImage}
   }
 }
 photos {
   photo {
     responsiveImage(imgixParams: { fit: crop, w: 1200, h: 750, auto: format }) {
       ${responsiveImage}
     }
   }
 }
 bundle
 hoppRecommended
 access
 overwriteLocation
 overwriteService
 addresscity
 addresspostcode
 addressstreet1
 addressstreet2
 contactname
 coverVideo {
   height
   provider
   providerUid
   thumbnailUrl
   title
   url
   width
 }
 dayrate
 email
 facebook
 faqs {
   answertext
   questiontext
 }
 howlonginthebusiness
 howmanypeopleinthecompany
 instagram
 internalnote
 latelicense
 linkedin
 offer
 offerother
 phone
 pinterest
 pricerange
 pricesstartingfrom
 publicliabilityinsurance
 seatedcapacity
 serviceareadescription
 standingcapacity
 suppliercategory {
   id
   category
   virtual
 }
 suppliercountry {
   country
   id
   iso2countrycode
 }
 suppliercounty {
   county
   id
 }
 supplierfeature {
   feature
   id
 }
 supplierregion {
   region
   id
 }
 supplierservices {
   service
   id
   icon {
     url
   }
   iconActive {
     url
   }
 }
 supplierPartners {
   ${supplier.query}
 }
 supplierstyle {
   id
   style
   image {
     url
   }
 }
 suppliersubservices {
   id
   subservice
 }
 customSection {
   title
   content
 }
 sustainability
 testimonials {
   testimonialname
   testimonialtext
 }
 twitter
 vatregistered
 website
 worksAbroad
 youtube
 stripeId
 stripeStatus
 stripeTrialEnd
 createdby
`

/**
 * Page Queries
 *
 * These queries are used in pages used for common site data.
 *
 */

export const seo = `
 seo: _site {
   favicon: faviconMetaTags {
     ${metaTags}
   }
   locales
   globalSeo {
    facebookPageUrl
    siteName
    titleSuffix
    twitterAccount
    fallbackSeo {
      title
      image {
        url(imgixParams: {fit: crop, w: "600"})
      }
      twitterCard
      description
    }
  }
 }
`
export const global = `
 global {
   homeHero {
     id
     title
     text
     image {
       url
     }
   }
   homeTitle
   homeSubtitle
   homeText
   homeButtonLabel
   homeSlider {
     id
     photo {
       url
     }
   }
   homeBackgroundVideo {
     id
     url
   }
   homeBackgroundVideoPoster {
     id
     url
   }
   joinText
   joinTitle
   joinSubtitle
   joinIcons {
     id
     label
     icon {
       url
     }
   }
   joinImage {
     responsiveImage(imgixParams: { fit: crop, w: 800, auto: format}) {
       ${responsiveImage}
     }
   }
   cardsCta {
     id
     title
     cta
     link
     backgroundImage {
      responsiveImage(imgixParams: { fit: crop, w: 560, auto: format}) {
        ${responsiveImage}
      }
     }
   }
 }
`

export const home = `
 landingPages: allPartyTypePages {
   id
   slug
   shortTitle
   featuredImage {
     responsiveImage(imgixParams: { fit: crop, w: 800, h: 900, auto: format}) {
       ${responsiveImage}
     }
   }
 }
 featuredSuppliers: allSuppliers(orderBy: ${supplier.defaultOrder}, first: "8") {
   ${supplier.query}
 }
 latestEdits: allProductEdits(orderBy: ${productEdit.defaultOrder}, first: "3") {
   ${productEdit.query}
 }
 latestPosts: allBlogs(orderBy: ${blog.defaultOrder}, first: "3") {
   ${blog.query}
 }
`

export const about = `
  about: aboutPage {
    title
    subtitle
    intro
    gallery {
      id
      photo {
        responsiveImage(imgixParams: { fit: crop, w: 2000, h: 900, auto: format}) {
          ${responsiveImage}
        }
      }
    }
    ourStory {
      id
      title
      text
      image {
        responsiveImage(imgixParams: { fit: crop, w: 800, h: 800, auto: format}) {
          ${responsiveImage}
        }
      }
    }
    ourValues {
      id
      title
      text
    }
    banner {
      ... on TextRecord {
        id
        markdown
      }
      ... on ButtonRecord {
        id
        label
      }
    }
    foundingStory {
      ... on TextRecord {
        id
        markdown
      }
      ... on PhotoRecord {
        id
        photo {
          responsiveImage(imgixParams: { fit: crop, w: 1200, auto: format}) {
            ${responsiveImage}
          }
        }
      }
    }
    team {
      id
      name
      image {
        responsiveImage(imgixParams: { fit: crop, w: 440, h:550, auto: format}) {
          ${responsiveImage}
        }
      }
    }
  }
  `

export const signup = `
  signup: signupPage {
    title
    intro
    featuresTitle
    features {
      id
      icon {
        url
        responsiveImage(imgixParams: { fit: crop, w: 600, auto: format}) {
          ${responsiveImage}
        }
      }
      text
      title
    }
    gallery {
      id
      title
      text
      icon {
        url
        responsiveImage(imgixParams: { fit: crop, w: 600, auto: format}) {
          ${responsiveImage}
        }
      }
    }
  }
  `

export const testimonialsPage = `
  testimonialsPage {
    title
    testimonials {
      id
      title
      location
      image {
        responsiveImage(imgixParams: { fit: crop, w: 700, h: 600, auto: format}) {
          ${responsiveImage}
        }
      }
      quote
      suppliers {
        id
        name
        category
        supplier {
          slug
        }
      }
    }
  }
  `
