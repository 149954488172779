import React from "react"
import Link from "next/link"
import * as styles from "./Menu.module.scss"
import useMediaQuery from "@lib/useMediaQuery"
import AuthenticationModal from "@components/auth/AuthenticationModal/AuthenticationModal"
import Logout from "@components/auth/LogOut/LogOut"
import Button from "@components/objects/Button/Button"
import accountIcon from "@images/icons/account.svg"
import accountGrey from "@images/icons/accountGrey.svg"
import cameraIcon from "@images/icons/cameraPink.svg"
import editGrey from "@images/icons/editGrey.svg"
import gearGrey from "@images/icons/gearGrey.svg"
import editIcon from "@images/icons/pencilPink.svg"
import saveStarPink from "@images/icons/saveStarPink.svg"
import searchIcon from "@images/icons/searchPink.svg"
import signOut from "@images/icons/signOut.svg"

const Menu = (props) => {
  const isMobile = useMediaQuery(1099)
  const isAdmin = props.userGroups && props.userGroups?.find((el) => el === "Admin")
  const isSupplier =
    props.userAttributes &&
    props.userAttributes["custom:status"] &&
    props.userAttributes["custom:status"] === "complete"
  const isSupplierOld =
    props.userAttributes && props.userAttributes["custom:status"] && props.userAttributes["custom:status"] === "paid"

  const profileInitials = props.userAttributes.given_name
    ? props.userAttributes.given_name.slice(0, 1) + props.userAttributes.family_name.slice(0, 1)
    : null

  const accountLinks = props.isAuthenticated ? (
    <ul className={styles.accountMenu}>
      <li>
        <button onMouseEnter={props.openAccountMenu}>
          Hey {props.userAttributes ? props.userAttributes.given_name : ""}
          {profileInitials ? (
            <span className={`${styles.profile} ${isAdmin ? styles.admin : ""}`}>{profileInitials}</span>
          ) : null}
        </button>
      </li>
      <ul onMouseLeave={props.closeAccountMenu} className={props.accountMenuOpen ? styles.active : ""}>
        {isAdmin ? (
          <li onClick={props.closeAccountMenu}>
            <Link href="/admin">
              <a>
                <img src={gearGrey} alt="" />
                Admin Dashboard
              </a>
            </Link>
          </li>
        ) : null}
        {isSupplier || isSupplierOld ? (
          <li onClick={props.closeAccountMenu}>
            <Link href="/supplier-dashboard">
              <a>
                <img src={gearGrey} alt="" />
                Supplier Dashboard
              </a>
            </Link>
          </li>
        ) : null}
        <li onClick={props.closeAccountMenu}>
          <Link href="/party-dashboard">
            <a>
              <img src={editGrey} alt="" />
              Party Dashboard
            </a>
          </Link>
        </li>
        <li onClick={props.closeAccountMenu}>
          <Link href="/account">
            <a>
              <img src={accountGrey} alt="" />
              Account Settings
            </a>
          </Link>
        </li>
        <li onClick={props.closeAccountMenu}>
          <Logout
            history={props.history}
            redirect="/signup"
            button={{
              text: "Sign out",
              image: signOut,
              design: "text",
              color: "white",
              size: "menu",
            }}
          />
        </li>
      </ul>
    </ul>
  ) : (
    <ul className={styles.accountMenu}>
      <li>
        <AuthenticationModal
          defaultStep="login"
          button={{
            text: "Log In",
            image: accountIcon,
            design: "text",
            color: "white",
          }}
        />
      </li>
    </ul>
  )
  const currParams = props.searchParams
  const urlParams = Object.keys(currParams)
    .map(
      (k) =>
        `${encodeURIComponent(k)}=${typeof currParams[k] === "object" ? JSON.stringify(currParams[k]) : currParams[k]}`
    )
    .join("&")

  return (
    <nav className={styles.Menu}>
      {accountLinks}
      {/* <ul>
        <li onMouseEnter={props.openSubMenu}>
          {!props.isAuthenticated ? (
            <div className={`${styles.submenu} ${props.subMenuOpen ? styles.active : ""}`}>
              <ul onMouseLeave={props.closeSubMenu}>
                <li onClick={props.closeSubMenu}>
                  <AuthenticationModal
                    defaultStep="login"
                    button={{
                      text: "Supplier log in",
                      image: accountIcon,
                      design: "text",
                      color: "white",
                    }}
                  />
                </li>
              </ul>
            </div>
          ) : null}
        </li>
      </ul> */}
      {isMobile ? (
        <ul>
          <li onClick={props.closeAccountMenu}>
            <Link href="/about">
              <a>About HOPP</a>
            </Link>
          </li>
          <li onClick={props.closeAccountMenu}>
            <Link href="/top-tips">
              <a>Top of the HOPPs</a>
            </Link>
          </li>
        </ul>
      ) : null}
      <div className={styles.buttons} onMouseEnter={props.closeSubMenu}>
        <Button
          link={"/suppliers-search" + (urlParams ? "/?" + urlParams : "")}
          image={searchIcon}
          text="Party Professionals"
          design="outline"
          color="pink"
        />
        {props.isAuthenticated ? (
          <Button link="/party-dashboard" image={saveStarPink} text="Party Dashboard" design="outline" color="pink" />
        ) : (
          <AuthenticationModal
            defaultStep="createParty"
            button={{
              text: "Start Planning",
              image: editIcon,
              design: "outline",
              color: "pink",
            }}
          />
        )}
        <Button link={"/moodboard-search"} image={cameraIcon} text="Gallery" design="outline" color="pink" />
      </div>
    </nav>
  )
}
export default Menu
