/*
 * Name: Button
 * Component: global
 * Props:  type, disabled, onClick, image, link, text, color, style
 * Default: standard solid light blue button with only text
 */
import React from "react"
import SVG from "react-inlinesvg"
import Link from "next/link"
import * as styles from "./Button.module.scss"
import { OutboundLink } from "@lib/gtag"

const Button = ({
  size = "medium",
  design = "solid",
  color = "lightBlue",
  className = "",
  mobileImageOnly,
  image,
  imageRight = false,
  imageHover,
  type = "button",
  disabled = false,
  externalLink = false,
  nofollow = false,
  link,
  text,
  rounded,
  onClick,
  title,
}) => {
  // setup props
  const classes = `${styles.Button} ${styles[design]} ${styles[color]} ${rounded && styles.rounded} ${styles[size]} ${
    mobileImageOnly && styles.mobileImageOnly
  } ${link && styles.link} ${imageRight && styles.imageRight} ${className}`
  image = image && (
    <SVG
      src={image}
      className={imageHover && styles.mainImage}
      preProcessor={(code) => code.replace(/style=".*?"/g, 'style=""')}
    />
  )
  imageHover = imageHover && (
    <SVG
      src={imageHover}
      className={styles.hoverImage}
      preProcessor={(code) => code.replace(/style=".*?"/g, 'style=""')}
    />
  )

  if (externalLink) {
    return (
      <OutboundLink
        className={classes}
        onClick={onClick}
        title={title}
        href={link}
        target="_blank"
        rel={`noopener noreferrer ${nofollow ? "nofollow" : ""}`}
      >
        {image}
        {imageHover}
        {text}
      </OutboundLink>
    )
  }

  if (link) {
    return (
      <Link title={title} onClick={onClick} href={link}>
        <a className={classes}>
          {image}
          {imageHover}
          {text}
        </a>
      </Link>
    )
  }

  return (
    <button className={classes} onClick={onClick} title={title} type={type} disabled={disabled}>
      {image}
      {imageHover}
      {text}
    </button>
  )
}

export default Button
