import { Auth } from "aws-amplify"
import React, { useState } from "react"
import { connect } from "react-redux"
import SiteWrapper from "../../structure/SiteWrapper/SiteWrapper"
import * as styles from "./SignUp.module.scss"
import { eventGtag } from "@lib/gtag"
import trackFBEvent from "@lib/trackFBEvent"
import fetchLambda from "@data/fetchLambda"
import lambdas from "@data/lambdas"
import * as actions from "@store/actions"
import SimpleIntro from "@components/layouts/global/SimpleIntro/SimpleIntro"
import Button from "@components/objects/Button/Button"
import hoppIconDark from "@images/icons/hoppIconDark.svg"

const { MAILCHIMP_REQUEST } = lambdas[process.env.NEXT_PUBLIC_ENV]

const SignUp = ({
  description = "Sign up to save your suppliers",
  changeModal,
  updateState,
  login,
  hasPartyToCreate,
  handleCreateParty,
  socialSignIn,
}) => {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    passwordConfirm: "",
    confirmNewsletter: true,
  })
  const [isProcessing, setIsProcessing] = useState(false)

  const handleChange = (event) => {
    event.persist()
    let { name, value, type, checked } = event.target
    if (name === "email") value = value.toLowerCase()

    setData((data) => ({
      ...data,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsProcessing(true)

    const { firstName, lastName, email, password, passwordConfirm, confirmNewsletter } = data

    if (passwordConfirm !== password) {
      alert("Passwords do not match. Please try again")
      setIsProcessing(false)
      return
    }

    try {
      const signUpResult = await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          given_name: firstName,
          family_name: lastName,
        },
      })

      if (signUpResult.userConfirmed) {
        updateState({
          email: email,
          password: password,
          firstName: firstName,
        })

        //analytics
        eventGtag({
          action: "Sent",
          params: {
            event_category: "Register",
            event_label: "HOPP",
          },
        })

        //newsletter signup
        if (confirmNewsletter) {
          await fetchLambda(MAILCHIMP_REQUEST, {
            data: {
              email: email,
              mergeFields: {
                FNAME: firstName,
                LNAME: lastName,
              },
            },
          })
          eventGtag({
            action: "Subscribed",
            params: {
              event_category: "Newsletter",
              event_label: "Signup",
              value: email,
            },
          })

          trackFBEvent("Subscribe", {
            user_email: email,
          })
        }

        //if party to load
        //instaed go loading and move fucntions from thanks to loading
        if (hasPartyToCreate) {
          changeModal("loading")
          login(email, password, async () => await handleCreateParty(null, true))
        } else {
          login(email, password, loadThanks)
        }

        trackFBEvent("CompleteRegistration", {
          user_email: email,
        })
      }
    } catch (e) {
      alert(e.message)
      setIsProcessing(false)
    }
  }

  const loadThanks = async () => {
    changeModal("thanks")
    let timeout = new Promise((resolve) => setTimeout(resolve, 3000))
    await timeout
  }

  return (
    <div className={styles.SignUp}>
      <SimpleIntro icon={hoppIconDark} title="Create a free account" description={description} />

      <SiteWrapper>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <div className={styles.twoColumn}>
            <input required type="text" name="firstName" placeholder="First Name" onChange={handleChange} />
            <input required type="text" name="lastName" placeholder="Surname" onChange={handleChange} />
          </div>
          <input
            required
            type="email"
            id="emailSignin"
            name="email"
            placeholder="Email address"
            value={data.email}
            onChange={handleChange}
          />

          <input
            required
            value={data.password}
            onChange={handleChange}
            type="password"
            id="passwordSignin"
            name="password"
            placeholder="Create Password"
          />
          <label>
            - Minimum 8 characters
            <br />- include at least one lowercase, one uppercase letter and one number
          </label>
          <input
            required
            value={data.confirmPassword}
            onChange={handleChange}
            type="password"
            id="passwordConfirmSignin"
            name="passwordConfirm"
            placeholder="Confirm Password"
          />
          <div className={styles.confirmNewsletter}>
            <input
              type="checkbox"
              id="confirmNewsletter"
              name="confirmNewsletter"
              checked={data.confirmNewsletter}
              onChange={handleChange}
            />
            <label htmlFor="confirmNewsletter">I want to receive HOPP news and updates</label>
          </div>
          <Button
            text={isProcessing ? "Loading..." : "Sign Up"}
            type="submit"
            disabled={isProcessing}
            className={isProcessing ? styles.processing : ""}
            color="pink"
          />
        </form>
        <h3>Or</h3>
        {/* facebook & goolge social login */}
        <div className={styles.socialLogin}>
          <Button
            className={styles.facebookButton}
            text={"Continue with Facebook"}
            type="button"
            onClick={() => socialSignIn("Facebook")}
          />
          <Button
            className={styles.googleButton}
            text={"Continue with Google"}
            type="button"
            onClick={() => socialSignIn("Google")}
          />
        </div>

        <div className={styles.login}>
          <span>Already a member?</span>
          <Button text="Login here" design="text" color="dark" onClick={() => changeModal("login")} />
        </div>
      </SiteWrapper>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password, callback) => dispatch(actions.login(email, password, callback)),
  }
}

export default connect(null, mapDispatchToProps)(SignUp)
