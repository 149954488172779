import moment from "moment"
import React, { Component } from "react"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import { SingleDatePicker } from "react-dates"
import { connect } from "react-redux"
import ReactSelect from "react-select"
import { budgetOptions } from "@data/static"
import { paddedSelectStyle } from "@data/static"
import SimpleIntro from "@components/layouts/global/SimpleIntro/SimpleIntro"
import Button from "@components/objects/Button/Button"
import GeocodeSelect from "@components/objects/GeocodeSelect/GeocodeSelect"
import closeDark from "@images/icons/closeDark.svg"
import partyIcon from "@images/icons/partyIcon.svg"
import SiteWrapper from "../../structure/SiteWrapper/SiteWrapper"
import * as styles from "./CreatePartyModal.module.scss"

class CreatePartyModal extends Component {
  state = {
    dateFocused: false,
  }

  handleSumbit = (event) => {
    event.preventDefault()

    this.props.handleCreateParty()
  }

  render() {
    const { categoryOptions, countyOptions } = this.props.staticData
    const partyCategory =
      this.props.partyCategory &&
      categoryOptions.find((el) => el.value === this.props.partyCategory)
    return (
      <form className={styles.CreatePartyModal} onSubmit={this.handleSumbit}>
        <SimpleIntro
          icon={
            partyCategory && partyCategory.iconActive ? partyCategory.iconActive.url : partyIcon
          }
          title={partyCategory ? "Create your " + partyCategory.label : "Create a new party"}
          description="Get started by giving HOPP a few details..."
        />
        <SiteWrapper className={styles.full}>
          <input
            required
            placeholder="Name your party*"
            name="partyName"
            onChange={this.props.handleChange}
          />
          <label>Keep it short and sweet!</label>
          {partyCategory ? null : (
            <>
              <ReactSelect
                placeholder="Type of party*"
                styles={paddedSelectStyle}
                className={styles.reactSelect}
                id="partyCategory"
                name="partyCategory"
                required
                isMulti={false}
                onChange={this.props.handleChangeSelect}
                options={categoryOptions}
              />
              <label>We’ll show you smart supplier recommendations</label>
            </>
          )}

          <ReactSelect
            placeholder="Party Budget*"
            styles={paddedSelectStyle}
            className={styles.reactSelect}
            id="partyBudget"
            name="partyBudget"
            required
            value={budgetOptions.filter(({ value }) => value === this.props.party.partyBudget)}
            isMulti={false}
            onChange={this.props.handleChangeSelect}
            options={budgetOptions}
          />
          <label>Choose your party budget</label>

          <GeocodeSelect
            required
            placeholder="Location*"
            onChange={this.props.handleChangeLocation}
            data={this.props.party}
            shortenInput={false}
            className={styles.select}
            useBrowserLocation={false}
          />
          <label>Find suppliers near your party</label>
          <SingleDatePicker
            date={
              this.props.party.partyDate && this.props.party.partyDate !== "0"
                ? moment(this.props.party.partyDate)
                : null
            }
            onDateChange={(date) => {
              this.props.handleChangeParty("partyDate", date ? +moment(date) : "0")
            }}
            focused={this.state.dateFocused}
            onFocusChange={({ focused }) => this.setState({ dateFocused: focused })}
            id="partyDate"
            numberOfMonths={1}
            noBorder
            readOnly
            displayFormat="D MMM YYYY"
            openDirection="up"
            hideKeyboardShortcutsPanel
            showClearDate
            customCloseIcon={<img src={closeDark} alt="clear date" />}
          />
          <label>When is your party taking place?</label>
        </SiteWrapper>
        <SiteWrapper className={`${styles.buttons} ${partyCategory ? styles.twoButtons : ""}`}>
          {partyCategory ? (
            <Button
              text={"Back"}
              className={styles.changepartyCategoryButton}
              color="grey"
              design="outline"
              type="button"
              onClick={() => this.props.changeModal("style")}
            />
          ) : null}
          <Button text={"Continue"} color="pink" type="submit" />
        </SiteWrapper>
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return { staticData: state.staticData.data }
}

export default connect(mapStateToProps)(CreatePartyModal)
