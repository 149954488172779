/*
 * Layout Name: Category Grid
 * Desc:
 */
import React from "react"
import ReactMarkdown from "react-markdown"
// components
import SiteWrapper from "@components/structure/SiteWrapper/SiteWrapper"
import * as styles from "./SimpleIntro.module.scss"

const SimpleIntro = ({ icon, title, description, className = "" }) => (
  <section className={`${styles.SimpleIntro} ${className}`}>
    <SiteWrapper>
      <img src={icon} alt="" />
      <h1>{title}</h1>
      <ReactMarkdown>{description}</ReactMarkdown>
    </SiteWrapper>
  </section>
)

export default SimpleIntro
