//fix model slugs from dato for autocomplete
export const datoModels = {
  SUPPLIER: "supplier",
  POST: "blog",
  GUIDE: "guide",
  NEWSLETTER_PAGE: "newsletterPage",
  INFO_PAGE: "infoPage",
  PARTY_TYPE_PAGE: "partyTypePage",
  PARTY_SERVICE_PAGE: "partyServicePage",
  PRODUCT_BRAND: "productBrand",
  PRODUCT_CATEGORY: "productCategory",
  PRODUCT_EDIT: "productEdit",
  PRODUCT: "product",
  SUPPLIER_CATEGORY: "suppliercategory",
  SUPPLIER_COUNTY: "suppliercounty",
  SUPPLIER_SERVICE: "supplierservice",
  SUPPLIER_SERVICE_CATEGORY: "Supplierservicecateogry",
  SUPPLIER_SUBSERVICE: "Suppliersubservice",
  SUPPLIER_STYLE: "supplierstyle",
  IMAGE_TAG: "imageTag",
  SUPPLIER_OPTIONS: { query: "supplierOptions", model: "supplier" },
}

export const budgetOptions = [
  { value: "0-£1000", label: "0-£1000" },
  { value: "£1000-£5000", label: "£1000-£5000" },
  { value: "£5000-£10,000", label: "£5000-£10,000" },
  { value: "£10,000-£50,000", label: "£10,000-£50,000" },
  { value: "£50,000-£100,000", label: "£50,000-£100,000" },
  { value: "£100,000+", label: "£100,000+" },
]

export const companySizeOptions = [
  { value: "1", label: "Sole trader" },
  { value: "1-10", label: "1-10" },
  { value: "10-25", label: "10-25" },
  { value: "25-75", label: "25-75" },
  { value: "75+", label: "75+" },
]

export const countryOptions = (stage) => [{ value: stage == "live" ? "666398" : "1149492", label: "United Kingdom" }]

//react select style overide
const reactSelectStyles = (style) => {
  const isPadddinglr = style === "paddedlr"
  const isPaddding = isPadddinglr || style === "padded"
  const isHomeHover = style === "homehover"
  const isHome = isHomeHover || style === "home"
  const isMinimum = style === "minimum"

  return {
    control: (base, state) => ({
      ...base,
      boxShadow: state.isFocused ? `0 0 0 0px #ccc` : 0,
      border: "none",
      paddingTop: isPaddding ? (isPadddinglr ? 0 : 10) : 0,
      paddingBottom: isPaddding ? (isPadddinglr ? 0 : 10) : 0,
      paddingLeft: isPaddding ? 20 : isHome ? 10 : isMinimum ? 5 : 0,
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 0.5,
      minHeight: isMinimum ? 46 : 38
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 20,
      top: isHome ? 60 : "auto",
    }),
    placeholder: (styles) => ({
      ...styles,
      color: isHomeHover ? "#d8000ca3" : "hsl(0,0%,50%)",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#fd78de",
      ":hover": {
        backgroundColor: "#fd78de",
        color: "white",
      },
    }),
  }
}

export const standardSelectStyle = reactSelectStyles()
export const homeSelectStyle = reactSelectStyles("home")
export const homeHoverSelectStyle = reactSelectStyles("homehover")
export const paddedSelectStyle = reactSelectStyles("padded")
export const lrpaddedSelectStyle = reactSelectStyles("paddedlr")
export const minimumSelectStyle = reactSelectStyles("minimum")

export const imageOrderOptions = [
  "size_ASC",
  "size_DESC",
  "resolution_ASC",
  "resolution_DESC",
  "filename_ASC",
  "filename_DESC",
  "basename_ASC",
  "basename_DESC",
]

export const userGroupOptions = {
  group: [
    { label: "Enquired", value: "SupplierEnquired" },
    { label: "Waitlisted", value: "SupplierWaitlisted" },
    { label: "Payment", value: "SupplierPayment" },
    { label: "Form", value: "SupplierForm" },
    { label: "Review", value: "SupplierReview" },
    { label: "Complete", value: "Supplier" },
  ],
  status: [
    { label: "Enquired", value: "enquired" },
    { label: "Waitlisted", value: "waitlisted" },
    { label: "Payment", value: "payment" },
    { label: "Form", value: "form" },
    { label: "Review", value: "review" },
    { label: "Complete", value: "complete" },
  ],
}

export const featureOptions = [
  { value: "1033786", label: "Are there bridal changing facilities?", dataServiceids: ["1033052"] },
  { value: "1033787", label: "Are you CBD checked?", dataServiceids: ["1033038"] },
  { value: "1033788", label: "Are you happy to stay late?", dataServiceids: ["1033030", "1033048", "1033053"] },
  { value: "1033789", label: "Can you cater in a marquee?", dataServiceids: ["1033037"] },
  { value: "1033790", label: "Can you provide a wedding cake?", dataServiceids: ["1033037"] },
  { value: "1033791", label: "Can you source alcohol?", dataServiceids: ["1033037"] },
  { value: "1033792", label: "Do you assist with council regulations for sound?", dataServiceids: ["1033035"] },
  { value: "1033793", label: "Do you charge corkage?", dataServiceids: ["1033037", "1033052"] },
  { value: "1033794", label: "Do you deliver?", dataServiceids: ["1033032"] },
  { value: "1033795", label: "Do you do a party/wedding consultation?", dataServiceids: ["1033036"] },
  { value: "1033797", label: "Do you do a site visit?", dataServiceids: ["1033036"] },
  { value: "1033796", label: "Do you do a site visit to venue?", dataServiceids: ["1033048", "1033053"] },
  { value: "1033802", label: "Do you do bespoke?", dataServiceids: ["1033031", "1033040", "1033041"] },
  { value: "1033798", label: "Do you do bespoke cakes?", dataServiceids: ["1033032"] },
  { value: "1033799", label: "Do you do bespoke draping?", dataServiceids: ["1033050"] },
  { value: "1033800", label: "Do you do bespoke flooring & carpets?", dataServiceids: ["1033050"] },
  { value: "1033801", label: "Do you do bespoke song requests?", dataServiceids: ["1033043"] },
  { value: "1033803", label: "Do you do bridal preperation shots?", dataServiceids: ["1033048", "1033053"] },
  { value: "1033804", label: "Do you do GIFs?", dataServiceids: ["1033046"] },
  { value: "1033805", label: "Do you do groups?", dataServiceids: ["1033030"] },
  { value: "1033806", label: "Do you do offer a tasting?", dataServiceids: ["1033034"] },
  { value: "1033811", label: "Do you have accomodation?", dataServiceids: ["1033052"] },
  { value: "1033812", label: "Do you have an alcohol license?", dataServiceids: ["1033052"] },
  { value: "1033813", label: "Do you have an assistant photographer?", dataServiceids: ["1033048", "1033053"] },
  { value: "1033814", label: "Do you have an in-house event manager?", dataServiceids: ["1033052"] },
  { value: "1033815", label: "Do you have an in-house generator?", dataServiceids: ["1033033", "1033042", "1033043"] },
  { value: "1033816", label: "Do you have an in-house PA system?", dataServiceids: ["1033043"] },
  { value: "1033817", label: "Do you have an onsite assistant?", dataServiceids: ["1033046"] },
  { value: "1033807", label: "Do you have a shop?", dataServiceids: ["1033032", "1033036", "1033041", "1033049"] },
  { value: "1033808", label: "Do you have a spa?", dataServiceids: ["1033052"] },
  { value: "1033809", label: "Do you have a star ceiling?", dataServiceids: ["1033050"] },
  { value: "1033810", label: "Do you have a wedding license?", dataServiceids: ["1033052"] },
  { value: "1033818", label: "Do you have disabled access?", dataServiceids: ["1033052"] },
  { value: "1033819", label: "Do you have in-house candles & vases?", dataServiceids: ["1033036"] },
  { value: "1033820", label: "Do you have in-house catering?", dataServiceids: ["1033052"] },
  { value: "1033822", label: "Do you have in-house furniture?", dataServiceids: ["1033050"] },
  { value: "1033821", label: "Do you have in-house furniture for hire?", dataServiceids: ["1033037", "1033045"] },
  { value: "1033823", label: "Do you have in-house generator?", dataServiceids: ["1033050"] },
  { value: "1033824", label: "Do you have in-house heating and cooling systems?", dataServiceids: ["1033050"] },
  { value: "1033825", label: "Do you have in-house lighting?", dataServiceids: ["1033043", "1033050"] },
  { value: "1033826", label: "Do you have in-house loo hire?", dataServiceids: ["1033050"] },
  { value: "1033827", label: "Do you have in-house props for hire?", dataServiceids: ["1033036", "1033045"] },
  { value: "1033828", label: "Do you have in-house skips?", dataServiceids: ["1033050"] },
  { value: "1033831", label: "Do you have in-house sound?", dataServiceids: ["1033033", "1033042"] },
  { value: "1033830", label: "Do you have in-house sound & lighting?", dataServiceids: ["1033052"] },
  { value: "1033829", label: "Do you have in-house sound & lighting equipment?", dataServiceids: ["1033045"] },
  { value: "1033832", label: "Do you have in-house tableware for hire?", dataServiceids: ["1033037"] },
  { value: "1033833", label: "Do you have in-house trackway?", dataServiceids: ["1033050"] },
  { value: "1033834", label: "Do you have parking?", dataServiceids: ["1033052"] },
  { value: "1033835", label: "Do you have patterned linings?", dataServiceids: ["1033050"] },
  { value: "1033836", label: "Do you loan items?", dataServiceids: ["1033041"] },
  { value: "1033841", label: "Do you offer an entertainment manager?", dataServiceids: ["1033038"] },
  { value: "1033842", label: "Do you offer any other pyrotechnics?", dataServiceids: ["1033035"] },
  { value: "1033837", label: "Do you offer a styling service?", dataServiceids: ["1033039"] },
  { value: "1033838", label: "Do you offer a tasting?", dataServiceids: ["1033037"] },
  { value: "1033843", label: "Do you offer bespoke menu options?", dataServiceids: ["1033037"] },
  { value: "1033844", label: "Do you offer bespoke music coordination?", dataServiceids: ["1033035"] },
  { value: "1033845", label: "Do you offer exclusive use?", dataServiceids: ["1033052"] },
  { value: "1033846", label: "Do you offer transparent ceiling?", dataServiceids: ["1033050"] },
  { value: "1033847", label: "Do you provide a chauffeur or driver?", dataServiceids: ["1033051"] },
  { value: "1033848", label: "Do you provide a fitting service?", dataServiceids: ["1033041"] },
  { value: "1033849", label: "Do you provide a highlights video?", dataServiceids: ["1033053"] },
  { value: "1033850", label: "Do you provide a hire service?", dataServiceids: ["1033040"] },
  { value: "1033855", label: "Do you provide albums?", dataServiceids: ["1033046", "1033048", "1033053"] },
  { value: "1033856", label: "Do you provide an engagement shoot?", dataServiceids: ["1033048", "1033053"] },
  { value: "1033857", label: "Do you provide an online gallery?", dataServiceids: ["1033046", "1033048", "1033053"] },
  { value: "1033858", label: "Do you provide an onsite lighting technician?", dataServiceids: ["1033033", "1033042"] },
  { value: "1033859", label: "Do you provide an onsite manager?", dataServiceids: ["1033050"] },
  { value: "1033863", label: "Do you provide an onsite sound technician?", dataServiceids: ["1033043"] },
  { value: "1033851", label: "Do you provide a pre party/wedding trial?", dataServiceids: ["1033030"] },
  { value: "1033854", label: "Do you provide a reveal curtain?", dataServiceids: ["1033050"] },
  { value: "1033860", label: "Do you provide artist management?", dataServiceids: ["1033043"] },
  { value: "1033852", label: "Do you provide a site visit?", dataServiceids: ["1033050"] },
  { value: "1033853", label: "Do you provide a transport manager?", dataServiceids: ["1033051"] },
  { value: "1033861", label: "Do you provide bartending?", dataServiceids: ["1033037"] },
  { value: "1033862", label: "Do you provide bespoke back-drops?", dataServiceids: ["1033046"] },
  { value: "1033864", label: "Do you provide CAD plans?", dataServiceids: ["1033033", "1033045"] },
  { value: "1033867", label: "Do you provide confetti?", dataServiceids: ["1033036"] },
  { value: "1033869", label: "Do you provide delivery?", dataServiceids: ["1033041", "1033049"] },
  { value: "1033865", label: "Do you provide delivery and collection?", dataServiceids: ["1033039"] },
  {
    value: "1033866",
    label: "Do you provide delivery, set up & breakdown?",
    dataServiceids: ["1033034", "1033036", "1033050", "1033046"],
  },
  { value: "1033868", label: "Do you provide digital calligraphy?", dataServiceids: ["1033049"] },
  { value: "1033870", label: "Do you provide digital editing?", dataServiceids: ["1033048", "1033053"] },
  { value: "1033871", label: "Do you provide E-invite design?", dataServiceids: ["1033049"] },
  { value: "1033872", label: "Do you provide floral aisles?", dataServiceids: ["1033036"] },
  { value: "1033873", label: "Do you provide glassware?", dataServiceids: ["1033034"] },
  { value: "1033874", label: "Do you provide invitation and save the date design?", dataServiceids: ["1033049"] },
  { value: "1033875", label: "Do you provide lasers?", dataServiceids: ["1033035"] },
  { value: "1033876", label: "Do you provide levelling for uneven terrain?", dataServiceids: ["1033050"] },
  { value: "1033877", label: "Do you provide lighting equipment?", dataServiceids: ["1033035"] },
  { value: "1033878", label: "Do you provide menu design?", dataServiceids: ["1033049"] },
  { value: "1033879", label: "Do you provide order of servie design?", dataServiceids: ["1033049"] },
  { value: "1033880", label: "Do you provide place and escort card design?", dataServiceids: ["1033049"] },
  { value: "1033882", label: "Do you provide pre-party/wedding consultation?", dataServiceids: ["1033048", "1033053"] },
  { value: "1033881", label: "Do you provide pre party/wedding design sketches?", dataServiceids: ["1033036"] },
  { value: "1033883", label: "Do you provide prints?", dataServiceids: ["1033046", "1033048", "1033053"] },
  { value: "1033884", label: "Do you provide props?", dataServiceids: ["1033046"] },
  { value: "1033885", label: "Do you provide pyrotechnics?", dataServiceids: ["1033033", "1033042", "1033043"] },
  { value: "1033886", label: "Do you provide set-up and breakdown?", dataServiceids: ["1033039"] },
  { value: "1033887", label: "Do you provide sound equipment?", dataServiceids: ["1033035"] },
  { value: "1033888", label: "Do you provide stage decor?", dataServiceids: ["1033033", "1033042", "1033043"] },
  { value: "1033889", label: "Do you provide table centrepieces?", dataServiceids: ["1033036"] },
  { value: "1033890", label: "Do you provide waiting staff?", dataServiceids: ["1033034", "1033037"] },
  { value: "1033891", label: "Do you provide washing up?", dataServiceids: ["1033039"] },
  { value: "1033892", label: "Do you provide wedding arches?", dataServiceids: ["1033036"] },
  { value: "1033893", label: "Do you require transport?", dataServiceids: ["1033038", "1033043"] },
  { value: "1033894", label: "Do you stay for touch-ups?", dataServiceids: ["1033030"] },
  { value: "1033895", label: "Is a marquee build permitted?", dataServiceids: ["1033052"] },
  { value: "1033896", label: "Is an external caterer permitted?", dataServiceids: ["1033052"] },
  { value: "1033897", label: "Is there a nearby church?", dataServiceids: ["1033052"] },
]

export const priceOptions = [
  { value: "1", label: "£" },
  { value: "2", label: "££" },
  { value: "3", label: "£££" },
  { value: "4", label: "££££" },
]

export const discountOptions = [
  // { value: "0", label: "None" },
  { value: "5", label: "5% Discount" },
  { value: "10", label: "10% Discount" },
  { value: "20", label: "20% Discount" },
  {
    value: "other",
    label: "Other, please write below",
  },
]

export const regionOptions = [
  { value: "724890", label: "Crown Dependencies" },
  { value: "724891", label: "East Midlands" },
  { value: "724894", label: "East of England" },
  { value: "724895", label: "London" },
  { value: "724897", label: "North East England" },
  { value: "724899", label: "Northern Ireland" },
  { value: "724898", label: "North West England" },
  { value: "724900", label: "Scotland" },
  { value: "724901", label: "South East England" },
  { value: "724902", label: "South West England" },
  { value: "724903", label: "Wales" },
  { value: "724904", label: "West Midlands" },
  { value: "724905", label: "Yorkshire and the Humber" }
]

export const formStageOptions = [
  "Contact",
  "Address",
  "Details",
  "Pricing",
  "Social",
  "Additional",
  "FAQ",
  "Testimonials",
  "Images",
  "Tags",
  "Review",
  "Thanks"
]

export const productOrderOptions = [
  { value: "_createdAt_DESC", label: "Newest" },
  { value: "_createdAt_ASC", label: "Oldest" },
  { value: "price_ASC", label: "Lowest Price" },
  { value: "price_DESC", label: "Highest Price" },
];

export const productPriceOptions = [
    { value: '{lte: 50}', label: "Under £50" },
    { value: '{gte: 50, lte: 100}', label: "£50-£100" },
    { value: '{gte: 100, lte: 250}', label: "£100-£250" },
    { value: '{gte: 250, "lte": 500}', label: "£250-500" },
    { value: '{gte: 500}', label: "Over £500" },
];


