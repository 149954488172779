import Cookies from "js-cookie"
import { useEffect, useState } from "react"
import { Image } from "react-datocms"
import { connect } from "react-redux"
import * as styles from "./LimitReached.module.scss"
import { eventGtag } from "@lib/gtag"
import trackFBEvent from "@lib/trackFBEvent"
import { queries, request } from "@data/datocms"
import fetchLambda from "@data/fetchLambda"
import lambdas from "@data/lambdas"
import * as actions from "@store/actions"
import Button from "@components/objects/Button/Button"
import { Auth } from "aws-amplify"


const { MAILCHIMP_REQUEST } = lambdas[process.env.NEXT_PUBLIC_ENV]

// runs on everypage
const LimitReached = ({ changeModal, lockSite, updateState, login }) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [newsletter, setNewsletter] = useState({})
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    passwordConfirm: "",
    confirmNewsletter: true,
  })

  useEffect(async () => {
    const { global } = await request({ query: `{ ${queries.newsletter} }` })
    setNewsletter(global)
  }, [])

  const handleChange = (event) => {
    event.persist()
    let { name, value, type, checked } = event.target
    if (name === "email") value = value.toLowerCase()

    setData((data) => ({
      ...data,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const loadThanks = async () => {
    changeModal("thanksBig")
    let timeout = new Promise((resolve) => setTimeout(resolve, 3000))
    await timeout
    lockSite(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsProcessing(true)

    const { firstName, lastName, email, password, passwordConfirm, confirmNewsletter } = data

    if (passwordConfirm !== password) {
      alert("Passwords do not match. Please try again")
      setIsProcessing(false)
      return
    }

    try {
      const signUpResult = await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          given_name: firstName,
          family_name: lastName,
        },
      })

      if (signUpResult.userConfirmed) {
        updateState({
          email: email,
          password: password,
          firstName: firstName,
        })

        //analytics
        eventGtag({
          action: "Sent",
          params: {
            event_category: "Register",
            event_label: "HOPP",
          },
        })

        //newsletter signup
        if (confirmNewsletter) {
          await fetchLambda(MAILCHIMP_REQUEST, {
            data: {
              email: email,
              mergeFields: {
                FNAME: firstName,
                LNAME: lastName,
              },
            },
          })
          eventGtag({
            action: "Subscribed",
            params: {
              event_category: "Newsletter",
              event_label: "Popup",
              value: email,
            },
          })

          trackFBEvent("Subscribe", {
            user_email: email,
          })
          Cookies.set("hopp_newsletter", "subscribed", { expires: 365 })
        }

        trackFBEvent("CompleteRegistration", {
          user_email: email,
        })

        login(email, password, loadThanks)
       
      }
    } catch (e) {
      alert(e.message)
      setIsProcessing(false)
    }
  }

  return (
    <section className={styles.LimitReached}>
      <div className={styles.grid}>
        <div>
          {newsletter.newsletterImage && (
            <Image className={styles.image} data={newsletter.newsletterImage?.responsiveImage} />
          )}
        </div>
        <div className={styles.content}>
          <h2>{newsletter.newsletterTitle}</h2>
          <p>{newsletter.newsletterDescription}</p>
          <form id="limitReached" onSubmit={handleSubmit}>
            <div className={styles.group}>
              <input type="text" name="firstName" placeholder="First name" onChange={handleChange} />
              <input type="text" name="lastName" placeholder="Last name" onChange={handleChange} />
            </div>
            <input type="email" name="email" placeholder="Enter email address" onChange={handleChange} />
      
            <input
              required
              value={data.password}
              onChange={handleChange}
              type="password"
              id="passwordSignin"
              name="password"
              placeholder="Create Password"
            />

            <input
              required
              value={data.confirmPassword}
              onChange={handleChange}
              type="password"
              id="passwordConfirmSignin"
              name="passwordConfirm"
              placeholder="Confirm Password"
            />
                  <label>
              - Minimum 8 characters
              <br />- include at least one lowercase, one uppercase letter and one number
            </label>
            <div className={styles.confirmNewsletter}>
              <input
                type="checkbox"
                id="confirmNewsletter"
                name="confirmNewsletter"
                checked={data.confirmNewsletter}
                onChange={handleChange}
              />
              <label htmlFor="confirmNewsletter">I want to receive HOPP news and updates</label>
            </div>
            <Button color="pink" type="submit" text={isProcessing ? "Loading..." : "Sign Up"} disabled={isProcessing} />
            <h3>Or</h3>
            <div className={styles.login}>
              <span>Already a member?</span>
              <Button text="Login here" design="text" color="dark" onClick={() => changeModal("login")} />
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
})

const mapDispatchToProps = (dispatch) => ({
  lockSite: (isLocked) => dispatch(actions.lockSite(isLocked)),
  login: (email, password, callback) => dispatch(actions.login(email, password, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LimitReached)
