import React, { Component } from "react"
import Modal from "react-modal"
import * as styles from "./ReactModal.module.scss"
import Button from "@components/objects/Button/Button"
import close from "@images/icons/closeBlue.svg"

Modal.setAppElement("#__next")

export default class ReactModal extends Component {
  state = {
    modalIsOpen: false,
  }

  openModal = () => {
    this.setState({ modalIsOpen: true })
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false })
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    return (
      <div>
        <Button
          {...this.props.button}
          type="button"
          onClick={this.props.modalIsOpen !== undefined ? this.props.openModal : this.openModal}
        />
        <Modal
          isOpen={this.props.modalIsOpen !== undefined ? this.props.modalIsOpen : this.state.modalIsOpen}
          onRequestClose={this.props.modalIsOpen !== undefined ? this.props.onRequestClose : this.closeModal}
          contentLabel={this.props.modalLabel}
          className={styles.modal + " " + this.props.className}
          overlayClassName={styles.overlay + " " + this.props.overlayClassName}
        >
          {!this.props.isLocked ? (
            <Button
              onClick={this.props.modalIsOpen !== undefined ? this.props.onRequestClose : this.closeModal}
              image={close}
              design="image"
              className={`${styles.close} ${this.props.closeClass}`}
            />
          ) : null}
          {this.props.children}
        </Modal>
      </div>
    )
  }
}
