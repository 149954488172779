/**
 * name: Section
 * component: layout
 */
import PropTypes from "prop-types"
import React from "react"
import * as styles from "./Section.module.scss"

const Section = ({ margin, inner, className, children, id, style }) => (
  <section
    id={id}
    className={`${styles.Section} ${className} ${inner && styles.inner} ${margin && styles.margin}`}
    style={style}
  >
    {children}
  </section>
)

Section.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Section
