import { useEffect, useState } from "react"

export const usePosition = () => {
  const [position, setPosition] = useState({})
  const [error, setError] = useState(null)

  const onChange = ({ coords }) => {
    if (position.latitude !== coords.latitude) {
      setPosition({
        latitude: coords.latitude,
        longitude: coords.longitude,
      })
    }
  }
  const onError = (error) => {
    setError(error.message)
  }
  useEffect(() => {
    const geo = navigator.geolocation
    if (!geo) {
      setError("Geolocation is not supported")
      return
    }
    const watcher = geo.watchPosition(onChange, onError)
    return () => geo.clearWatch(watcher)
  }, []) //eslint-disable-line
  return { ...position, error }
}
