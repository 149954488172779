import React, { useEffect, useState } from "react"
import Lottie from "react-lottie"
import ReactMarkdown from "react-markdown"
import SimpleIntro from "@components/layouts/global/SimpleIntro/SimpleIntro"
import Button from "@components/objects/Button/Button"
import SiteWrapper from "@components/structure/SiteWrapper/SiteWrapper"
import partyIcon from "@images/icons/partyIcon.svg"
import * as styles from "./LoadingParty.module.scss"
import animationData from "./hopp-loading.json"

const LoadingParty = (props) => {
  const [isStopped, disableAnimation] = useState(true)
  const [isComplete, complete] = useState(false)

  const lottieOptions = {
    loop: true,
    autoplay: false,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  useEffect(() => disableAnimation(false), [])

  useEffect(() => {
    if (isComplete && props.callback) {
      props.callback()
    }
  }, [isComplete, props])

  return (
    <section className={`${styles.LoadingParty} ${props.className ? props.className : ""}`}>
      <SimpleIntro icon={partyIcon} title={props.title} description={props.text} />

      <SiteWrapper className={styles.wrapper}>
        <div className={`${styles.animation} ${isComplete ? styles.completed : ""}`}>
          <Lottie
            options={lottieOptions}
            height={320}
            width={320}
            speed={0.9}
            isStopped={isStopped}
            isPaused={isStopped}
            eventListeners={[
              {
                eventName: "complete",
                callback: () => complete(true),
              },
            ]}
          />
        </div>
      </SiteWrapper>
    </section>
  )
}

export default LoadingParty
