import Link from "next/link"
import React from "react"
import * as styles from "./SiteLogo.module.scss"

const SiteLogo = (props) => {
  const color = props.dark ? "#131730" : "#ffffff"

  return (
    <Link href="/">
      <a>
        <div className={`${props.className} ${styles.SiteLogo}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="112" height="33" viewBox="0 0 112 33">
            <g transform="translate(-72 -41)">
              <path
                className={styles.letters}
                fill={color}
                d="M111.249 51.441c0-2.58 2.058-4.67 4.596-4.67 2.538 0 4.596 2.09 4.596 4.67s-2.058 4.671-4.596 4.671c-2.538 0-4.596-2.091-4.596-4.67zm14.87 0c0-5.766-4.6-10.441-10.274-10.441-5.674 0-10.274 4.675-10.274 10.441 0 5.767 4.6 10.441 10.274 10.441 5.674 0 10.274-4.674 10.274-10.44zm43.41-3.695h2.514c2.761 0 4.142 1.219 4.142 3.658s-1.38 3.658-4.142 3.658h-2.513zm0 13.483H174c3.247 0 5.733-.857 7.458-2.572 1.726-1.714 2.543-4.13 2.543-7.248 0-3.118-.817-5.534-2.543-7.249-1.725-1.714-4.211-2.572-7.458-2.572h-11.994V71h7.525zm-27.782-13.464h2.799c2.757 0 4.137 1.236 4.137 3.681 0 2.446-1.38 3.682-4.137 3.682h-2.8zm.003 13.53h4.471c3.249 0 5.736-.923 7.462-2.638 1.726-1.714 2.544-4.098 2.544-7.216 0-3.117-.818-5.566-2.544-7.28-1.726-1.715-4.213-2.573-7.462-2.573h-11.999V71h7.528zM79.525 41.587H72V71h7.525V59.676h10.418V71h7.525V41.588h-7.525V52.03H79.525z"
              />
              <path
                className={styles.bow}
                fill="#ff78de"
                d="M106.526 64L116 66.56l9.474-2.56a15.66 15.66 0 0 1 .526 4 15.66 15.66 0 0 1-.526 4L116 69.44 106.526 72a15.66 15.66 0 0 1-.526-4c0-1.333.175-2.667.526-4z"
              />
            </g>
          </svg>
        </div>
      </a>
    </Link>
  )
}

export default SiteLogo
