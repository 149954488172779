import React from "react"
import { renderMetaTags } from "react-datocms"
import Head from "next/head"

function Seo({ description, title, image, global, meta }) {
  if (!global || !global.favicon) {
    return null
  }

  const { favicon, globalSeo } = global
  const metaDescription = description || globalSeo?.fallbackSeo.description
  const metaImage = image || globalSeo?.fallbackSeo.image.url
  const metaTitle = `${title || globalSeo?.fallbackSeo.title}${globalSeo?.titleSuffix}`

  return (
    <Head>
      {renderMetaTags(favicon)}
      {meta ? (
        renderMetaTags(meta)
      ) : (
        <>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={metaDescription} />
          <meta property="og:image" content={metaImage} />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content="HOPP" />
          <meta name="twitter:title" content={metaTitle} />
          <meta name="twitter:description" content={metaDescription} />
          <meta name="twitter:image" content={metaImage} />
        </>
      )}
    </Head>
  )
}
export default Seo
