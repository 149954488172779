import React, { useState } from "react"
import { connect } from "react-redux"
import SimpleIntro from "@components/layouts/global/SimpleIntro/SimpleIntro"
import Button from "@components/objects/Button/Button"
import SiteWrapper from "@components/structure/SiteWrapper/SiteWrapper"
import partyIcon from "@images/icons/partyIcon.svg"
import * as styles from "./SelectStyle.module.scss"

const SelectStyle = ({ staticData, changeModal, partyStyle, handleChangeParty }) => {
  const { styleOptions } = staticData
  return (
    <div className={styles.SelectStyle}>
      <SimpleIntro icon={partyIcon} title="What's your style?" />
      <SiteWrapper className={styles.styles} full>
        {styleOptions.map((style) => (
          <div
            key={style.value}
            onClick={() => handleChangeParty("partyStyle", style.value)}
            className={`${styles.style} ${partyStyle === style.value ? styles.active : ""}`}
          >
            <div className={styles.image}>
              <img src={`${style.image.url}?w=600&h=375&fit=crop&fm=jpg`} alt="" />
            </div>
            <span>{style.label}</span>
          </div>
        ))}
      </SiteWrapper>
      <SiteWrapper className={styles.buttons}>
        <Button
          text="Back"
          className={styles.back}
          color="grey"
          design="outline"
          type="button"
          onClick={() => changeModal("services")}
        />
      </SiteWrapper>
    </div>
  )
}

const mapStateToProps = (state) => ({ staticData: state.staticData.data })

export default connect(mapStateToProps)(SelectStyle)
