import React, { useEffect, useState } from "react"
import Lottie from "react-lottie"
import ReactMarkdown from "react-markdown"
import Button from "@components/objects/Button/Button"
import SiteWrapper from "@components/structure/SiteWrapper/SiteWrapper"
import animationData from "./433-checked-done-pink.json"
import * as styles from "./ThankYou.module.scss"

const ThankYou = (props) => {
  const [isStopped, disableAnimation] = useState(true)
  const [isComplete, complete] = useState(false)

  const lottieOptions = {
    loop: false,
    autoplay: false,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  useEffect(() => disableAnimation(false), [])

  useEffect(() => {
    if (isComplete && props.callback) {
      props.callback()
    }
  }, [isComplete, props])

  return (
    <section className={`${styles.ThankYou} ${props.className ? props.className : ""}`}>
      <SiteWrapper className={styles.wrapper}>
        <div className={`${styles.animation} ${isComplete ? styles.completed : ""}`}>
          <Lottie
            options={lottieOptions}
            height={320}
            width={320}
            isStopped={isStopped}
            isPaused={isStopped}
            eventListeners={[
              {
                eventName: "complete",
                callback: () => complete(true),
              },
            ]}
          />
        </div>
        <div className={`${styles.text} ${isComplete ? styles.completed : ""}`}>
          <h2>{props.title}</h2>
          <ReactMarkdown>{props.text}</ReactMarkdown>
          {props.button && <Button {...props.button} />}
        </div>
      </SiteWrapper>
    </section>
  )
}

export default ThankYou
