import React, { Component } from "react"
import { connect } from "react-redux"
import * as actions from "@store/actions"
import SimpleIntro from "@components/layouts/global/SimpleIntro/SimpleIntro"
import Button from "@components/objects/Button/Button"
import hoppIconDark from "@images/icons/hoppIconDark.svg"
import SiteWrapper from "../../structure/SiteWrapper/SiteWrapper"
import * as styles from "./ForgotPassword.module.scss"

class ForgotPassword extends Component {
  state = {
    email: "",
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault()

    if (this.state.email) {
      try {
        const result = await this.props.forgotPassword(this.state.email)
        if (result.CodeDeliveryDetails) {
          this.props.changeModal("forgotVerify")
        } else {
          alert(result.message || "Error occurred, please try again")
        }
      } catch (e) {
        alert(e.message)
      }
    } else {
      alert("Please enter the email address you registered with")
    }
  }

  render() {
    return (
      <section className={styles.ForgotPassword}>
        <SimpleIntro
          icon={hoppIconDark}
          title="Forgot your password?"
          description="Enter your email to get your verification code"
        />
        <SiteWrapper>
          <form>
            <input
              autoFocus
              placeholder="Email address"
              type="email"
              id="emailForgotPasswordVerify"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
              required
            />
            <div className={styles.buttons}>
              <Button
                text={"Back"}
                color="grey"
                design="outline"
                onClick={() => this.props.changeModal("login")}
              />
              <Button
                text={"Send reset link"}
                type="submit"
                color="pink"
                onClick={this.handleSubmit}
              />
            </div>
            <p>
              Already have a code?{" "}
              <Button
                design="text"
                color="dark"
                onClick={() => this.props.changeModal("forgotVerify")}
                text="Click here"
              />
            </p>
          </form>
        </SiteWrapper>
      </section>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (email) => dispatch(actions.forgotPassword(email)),
  }
}

export default connect(null, mapDispatchToProps)(ForgotPassword)
