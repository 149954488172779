import React from "react"
import { connect } from "react-redux"
import CategoryGrid from "@components/layouts/global/CategoryGrid/CategoryGrid"
import SimpleIntro from "@components/layouts/global/SimpleIntro/SimpleIntro"
import partyIcon from "@images/icons/partyIcon.svg"
import * as styles from "./NewPartyModal.module.scss"

const ChangepartyCategoryModal = (props) => {
  const createParty = (partyCategory) => {
    props.changeModal("services")
    props.handleChangeParty("partyCategory", partyCategory)
  }

  return (
    <div className={styles.stepgrid}>
      <SimpleIntro
        icon={partyIcon}
        title="What are you looking for?"
        description="Please select your party type"
      />
      <CategoryGrid
        inModal
        openCreateParty={createParty}
        categories={props.staticData.categoryOptions}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({ staticData: state.staticData.data })

export default connect(mapStateToProps)(ChangepartyCategoryModal)
