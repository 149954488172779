import React, { Component } from "react"
import { connect } from "react-redux"
import * as actions from "@store/actions"
import SimpleIntro from "@components/layouts/global/SimpleIntro/SimpleIntro"
import Button from "@components/objects/Button/Button"
import hoppIconDark from "@images/icons/hoppIconDark.svg"
import SiteWrapper from "../../structure/SiteWrapper/SiteWrapper"
import * as styles from "./ForgotPassword.module.scss"

class ForgotPasswordVerify extends Component {
  state = {
    email: "",
    code: this.props.resetCode ? this.props.resetCode : "",
    password: "",
    passwordConfirm: "",
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const result = await this.props.forgotPasswordSubmit(
        this.state.email,
        this.state.code,
        this.state.password
      )
      if (result && result.message) {
        alert(result.message)
      } else {
        this.props.changeModal("login")
      }
    } catch (e) {
      alert(e.message)
    }
  }

  render() {
    return (
      <div className={styles.ForgotPassword}>
        <SimpleIntro
          icon={hoppIconDark}
          title="Reset your password"
          description={"Check your email for the verification code"}
        />
        <SiteWrapper>
          <form>
            <input
              autoFocus
              placeholder="Email address"
              type="email"
              id="emailForgotPasswordVerify"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
              required
            />
            <input
              type="code"
              placeholder="Verification Code"
              id="codeForgotPasswordVerify"
              name="code"
              value={this.state.code}
              onChange={this.handleChange}
              required
            />
            <input
              placeholder="New Password"
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
              id="passwordForgotPasswordVerify"
              name="password"
              required
            />
            <input
              placeholder="Confirm New Password"
              value={this.state.confirmPassword}
              onChange={this.handleChange}
              type="password"
              id="passwordConfirmForgotPasswordVerify"
              name="passwordConfirm"
              required
            />
            <div className={styles.buttons}>
              <Button
                text={"Back"}
                color="grey"
                design="outline"
                onClick={() => this.props.changeModal("forgot")}
              />
              <Button text={"Login"} color="pink" type="submit" onClick={this.handleSubmit} />
            </div>
          </form>
        </SiteWrapper>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPasswordSubmit: (email, code, newPassword) =>
      dispatch(actions.forgotPasswordVerify(email, code, newPassword)),
    partyLoadFirst: () => dispatch(actions.partyLoadFirst()),
  }
}

export default connect(null, mapDispatchToProps)(ForgotPasswordVerify)
