/**
 * name: SiteWrapper
 * component: layout
 */
import PropTypes from "prop-types"
import React from "react"
import * as styles from "./SiteWrapper.module.scss"

const SiteWrapper = ({
  children,
  wide,
  full,
  desktopOnly,
  mobileOnly,
  className,
  narrow,
  textNarrow,
}) => {
  const classes = `${wide ? styles.wide : ""} ${full ? styles.full : ""} ${
    narrow ? styles.narrow : ""
  } ${textNarrow ? styles.textNarrow : ""} ${desktopOnly ? styles.desktopOnly : ""} ${
    mobileOnly ? styles.mobileOnly : ""
  } ${className} ${styles.SiteWrapper} `
  return <div className={classes}>{children}</div>
}

SiteWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SiteWrapper
