import { GraphQLClient } from "graphql-request"
import * as queries from "./queries"
import { DATO_MAX_FIRST, DEFAULT_INITIAL_LOAD_NUM_RECORDS } from "@data/constants"
import { datoModels } from "@data/static"

export function request({ query, variables, preview }) {
  const endpoint = `https://graphql.datocms.com/environments/${process.env.NEXT_PUBLIC_DATOCMS_ENVIRONMENT}/${
    preview ? "preview" : ""
  }`
  const client = new GraphQLClient(endpoint, {
    headers: {
      authorization: `Bearer ${process.env.NEXT_PUBLIC_DATOCMS_API_TOKEN}`,
    },
  })
  return client.request(query, variables)
}

export const getSubscription = async (graphqlRequest) => {
  return {
    ...graphqlRequest,
    initialData: await request(graphqlRequest),
    token: process.env.NEXT_PUBLIC_DATOCMS_API_TOKEN,
    environment: process.env.NEXT_PUBLIC_DATOCMS_ENVIRONMENT || null,
  }
}

export const getDatoModel = (modelKey) => {
  let model = typeof modelKey === "object" ? modelKey.model : modelKey

  //captilaize the first letter of the model and create the pluralized version
  model = model.charAt(0).toUpperCase() + model.slice(1)
  const pluralModel = model.substr(model.length - 1) === "y" ? `${model.slice(0, -1)}ies` : `${model}s`

  return { model, pluralModel }
}

// generate queries for dato models that are not single instances
export const getQuery = (
  modelName,
  first = DEFAULT_INITIAL_LOAD_NUM_RECORDS,
  skip = 0,
  orderBy = null,
  pathsOnly = false
) => {
  const queryKey = typeof modelName === "object" ? modelName.query : modelName

  const { model, pluralModel } = getDatoModel(modelName)

  //model name same as query data name
  const { query, defaultOrder } = queries[queryKey]

  return {
    query: `
      query ${model}Query($first: IntType, $skip: IntType, $orderBy: [${model}ModelOrderBy]) {
        all${pluralModel}(first: $first, skip: $skip, orderBy: $orderBy) {
          ${pathsOnly ? "slug" : query}
        }
        _all${pluralModel}Meta {
          count
        }
      }
    `,
    variables: { first, skip, orderBy: orderBy || defaultOrder },
  }
}

// internal function to use query to get a slice of data from model
const getSomeRecords = async (modelKey, first, skip, orderBy = null, pathsOnly = false) => {
  const query = getQuery(modelKey, first, skip, orderBy, pathsOnly)
  const data = await request(query)
  const { pluralModel } = getDatoModel(modelKey)
  return { records: data[`all${pluralModel}`], count: data[`_all${pluralModel}Meta`].count }
}

//exported function that handels ordering
export const getRecords = async (
  modelKey,
  first = DEFAULT_INITIAL_LOAD_NUM_RECORDS,
  skip = 0,
  orderBy = null,
  order = "DESC"
) => {
  const formattedOrder = orderBy && `${orderBy}_${order}`
  return await getSomeRecords(modelKey, first, skip, formattedOrder)
}

//paginated requests for all records in a model
export const getAllRecords = async (modelKey, orderBy = null, order = "DESC", pathsOnly = false) => {
  //format order
  const formattedOrder = orderBy && `${orderBy}_${order}`

  //get first set
  let { records, count } = await getSomeRecords(modelKey, DATO_MAX_FIRST, 0, formattedOrder, pathsOnly)

  if (count > DATO_MAX_FIRST) {
    const remainingLoopCount = Math.floor(count / DATO_MAX_FIRST)

    for (let i = 1; i <= remainingLoopCount; i++) {
      const { records: newRecords } = await getSomeRecords(
        modelKey,
        DATO_MAX_FIRST,
        i * DATO_MAX_FIRST,
        formattedOrder,
        pathsOnly
      )
      records = [...records, ...newRecords]
    }
  }

  return { records, count }
}

export { queries }
