import React, { Component } from "react"
import { connect } from "react-redux"
import * as actions from "@store/actions"
import Button from "@components/objects/Button/Button"
import * as styles from "./LogOut.module.scss"

class Logout extends Component {
  handleClick = async (event) => {
    event.preventDefault()
    try {
      await this.props.signOut()
    } catch (e) {
      alert(e.message)
    }
  }

  render() {
    return (
      <Button
        {...this.props.button}
        className={styles.LogOut}
        onClick={this.handleClick}
        type="button"
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(actions.signOut()),
  }
}

export default connect(null, mapDispatchToProps)(Logout)
