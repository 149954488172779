import React from "react"
import * as styles from "./SocialLinks.module.scss"
import { OutboundLink } from "@lib/gtag"
import facebookBlue from "@images/icons/facebookBlue.svg"
import facebookGrey from "@images/icons/facebookGrey.svg"
import websiteGrey from "@images/icons/globeIcon.svg"
import webbsiteDark from "@images/icons/globeIconDark.svg"
import instagramGrey from "@images/icons/instagramGrey.svg"
import instagramOrange from "@images/icons/instagramOrange.svg"
import linkedinDark from "@images/icons/linkedInDark.svg"
import linkedinGrey from "@images/icons/linkedInGrey.svg"
import twitterGrey from "@images/icons/twitterGrey.svg"
import twitterPink from "@images/icons/twitterPink.svg"

const SLink = ({ href, isColor, greyIcon, colorIcon, alt, trackClick }) => (
  <OutboundLink href={href} target="_blank" rel="noopener noreferrer">
    <span
      onClick={() => {
        trackClick && trackClick(alt)
      }}
    >
      <img src={isColor ? colorIcon : greyIcon} alt={alt} />
    </span>
  </OutboundLink>
)

const SocialLinks = ({ facebook, twitter, instagram, linkedin, website, color, trackClick, className = "" }) =>
  facebook || twitter || instagram || linkedin ? (
    <div className={`${styles.SocialLinks} ${className}`}>
      {website && (
        <SLink
          href={website}
          isColor={color}
          greyIcon={websiteGrey}
          colorIcon={webbsiteDark}
          alt="Website"
          trackClick={trackClick}
        />
      )}
      {facebook && (
        <SLink
          href={facebook}
          isColor={color}
          greyIcon={facebookGrey}
          colorIcon={facebookBlue}
          alt="Facebook"
          trackClick={trackClick}
        />
      )}
      {twitter && (
        <SLink
          href={twitter}
          isColor={color}
          greyIcon={twitterGrey}
          colorIcon={twitterPink}
          alt="Twitter"
          trackClick={trackClick}
        />
      )}
      {instagram && (
        <SLink
          href={instagram}
          isColor={color}
          greyIcon={instagramGrey}
          colorIcon={instagramOrange}
          alt="Instagram"
          trackClick={trackClick}
        />
      )}
      {linkedin && (
        <SLink
          href={linkedin}
          isColor={color}
          greyIcon={linkedinGrey}
          colorIcon={linkedinDark}
          alt="Linkedin"
          trackClick={trackClick}
        />
      )}
    </div>
  ) : null

export default SocialLinks
