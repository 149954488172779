import React, { useState } from "react"
import Masonry from "react-masonry-css"
import { connect } from "react-redux"
import SimpleIntro from "@components/layouts/global/SimpleIntro/SimpleIntro"
import Button from "@components/objects/Button/Button"
import SiteWrapper from "@components/structure/SiteWrapper/SiteWrapper"
import partyIcon from "@images/icons/partyIcon.svg"
import * as styles from "./SelectPartyServices.module.scss"

const SelectPartyServices = ({
  staticData,
  changeModal,
  partyServices,
  handleUpdatepartyServices,
}) => {
  const { serviceCategoryOptions } = staticData
  return (
    <div className={styles.SelectPartyServices}>
      <SimpleIntro
        icon={partyIcon}
        title="What kind of suppliers will you need?"
        description="Please select all relevant services"
      />
      <SiteWrapper className={styles.full}>
        <Masonry
          breakpointCols={{
            default: 3,
            700: 2,
            500: 1,
          }}
          className={styles.serviceCategories}
          columnClassName={styles.serviceCategoriesColumn}
        >
          {serviceCategoryOptions.map((serviceCategory) => (
            <div key={serviceCategory.id} className={styles.serviceCategory}>
              <h3>{serviceCategory.label}</h3>
              {serviceCategory.options.map((service) => {
                const active = !!partyServices.find((el) => el === service.value)
                return (
                  <div
                    key={service.value}
                    onClick={() => handleUpdatepartyServices(service.value, !active)}
                    className={`${styles.service} ${active ? styles.active : ""}`}
                  >
                    <img src={service.icon.url} alt="" />
                    <label>{service.label}</label>
                  </div>
                )
              })}
            </div>
          ))}
        </Masonry>
      </SiteWrapper>
      <SiteWrapper className={styles.buttons}>
        <Button
          text="Back"
          className={styles.back}
          color="grey"
          design="outline"
          type="button"
          onClick={() => changeModal("category")}
        />

        <Button text="Continue" color="pink" type="button" onClick={() => changeModal("style")} />
      </SiteWrapper>
    </div>
  )
}

const mapStateToProps = (state) => ({ staticData: state.staticData.data })

export default connect(mapStateToProps)(SelectPartyServices)
