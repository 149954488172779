import React from "react"
import Link from "next/link"
import { useRouter } from "next/router"
import * as styles from "./Foundation.module.scss"
import AuthenticationModal from "@components/auth/AuthenticationModal/AuthenticationModal"
import NewsletterSignUp from "@components/global/NewsletterSignUp/NewsletterSignUp"
import SiteLogo from "@components/global/SiteLogo/SiteLogo"
import SocialLinks from "@components/global/SocialLinks/SocialLinks"
import SiteWrapper from "@components/structure/SiteWrapper/SiteWrapper"

const Foundation = () => {
  const router = useRouter()

  return (
    <footer className={styles.Foundation}>
      <SiteWrapper>
        <div className={styles.grid}>
          <SiteLogo dark className={styles.logo} />

          <div className={styles.menus}>
            <div className={styles.nav}>
              <Link href="/testimonials">
                <a>Testimonials</a>
              </Link>
              <Link href="/become-a-supplier">
                <a>Become a supplier</a>
              </Link>
              <Link href="/top-tips">
                <a>
                  Top of the HOPP
                  <span>s</span>
                </a>
              </Link>
              <Link href="/about">
                <a>About HOPP</a>
              </Link>
              <a href="mailto:hello@houseofpartyplanning.com">hello@houseofpartyplanning.com</a>
            </div>
            <div className={styles.subnav}>
              <Link href="/user-terms">
                <a>User Terms</a>
              </Link>
              <Link href="/cookie-policy">
                <a>Cookie Policy</a>
              </Link>
              <Link href="/privacy-policy">
                <a>Privacy Policy</a>
              </Link>
              <Link href="/supplier-terms">
                <a>Supplier Terms</a>
              </Link>
              <Link target="_blank" rel="noopener noreferrer" href="https://bmas.agency/">
                <a> Web: BMAS</a>
              </Link>

              <span className={styles.copyright}>©{new Date().getFullYear()} Hopp. All rights reserved</span>
            </div>
          </div>

          <SocialLinks
            facebook="https://www.facebook.com/houseofpartyplanning/"
            instagram="https://www.instagram.com/houseofpartyplanning"
          />
        </div>
      </SiteWrapper>

      <NewsletterSignUp
        title="STAY UPDATED"
        description="Hear about special events, discounts on brands and new suppliers"
      />
      {router?.query?.auth && (
        <AuthenticationModal
          openOnLoad={true}
          resetCode={router.query.code}
          defaultStep={router.query.auth}
          button={{ design: "hidden" }}
        />
      )}
    </footer>
  )
}

export default Foundation
