/*
 * Layout Name: Category Grid
 * Desc:
 */
import React, { Component } from "react"
// components
import SiteWrapper from "@components/structure/SiteWrapper/SiteWrapper"
import star from "@images/icons/saveStarOrange.svg"
// assests
import * as styles from "./CategoryGrid.module.scss"

const CategoryGrid = (props) => {
  const handleSubmit = (category) => {
    if (props.inModal) {
      props.openCreateParty(category.value)
    } else if (category.virtual) {
      props.openSearch(category.value)
    } else {
      props.openServiceStep(category.value)
    }
  }

  const { categories } = props
  categories.sort((a, b) => b.virtual - a.virtual)
  const categoriesElement =
    categories && categories.length
      ? categories.map((category) => {
          const layout = (
            <>
              <div className={styles.images}>
                {category.icon ? <img src={category.icon.url} alt={category.label} /> : null}
                {category.iconActive ? (
                  <img src={category.iconActive.url} alt={`${category.label} active`} />
                ) : null}
              </div>
              <span>{category.label}</span>
            </>
          )

          return category ? (
            <li key={category.value} className={styles.box}>
              {category.virtual && <span className={styles.newIcon}>New</span>}
              <div
                className={`${styles.boxInner} ${category.virtual ? styles.virtual : ""}`}
                onClick={() => handleSubmit(category)}
              >
                {layout}
              </div>
            </li>
          ) : null
        })
      : null

  return (
    <section className={`${styles.CategoryGrid} ${props.inModal && styles.inModal}`}>
      <SiteWrapper full>
        <ul className={styles.grid}>{categoriesElement}</ul>
      </SiteWrapper>
    </section>
  )
}

export default CategoryGrid
