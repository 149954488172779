import mbxGeocoding from "@mapbox/mapbox-sdk/services/geocoding"
import React, { useEffect, useRef, useState } from "react"
import * as styles from "./GeocodeSelect.module.scss"
import { usePosition } from "@lib/usePosition"
import { GEOLOCATION_DEFAULT_RADIUS } from "@data/constants"
import closeDark from "@images/icons/closeDark.svg"

const NEXT_PUBLIC_MAPBOX_API_SK = process.env.NEXT_PUBLIC_MAPBOX_API_SK

let geocodingClient
if (typeof window !== "undefined") {
  geocodingClient = mbxGeocoding({
    accessToken: NEXT_PUBLIC_MAPBOX_API_SK,
  })
}

const GeocodeSelect = ({
  onChange,
  data,
  placeholder,
  required,
  className,
  shortenInput = true,
  useBrowserLocation = true,
  loadBrowserLocation,
  hasLoadedBrowserLocation,
}) => {
  const [placeSuggestions, setPlaceSuggestions] = useState()
  const [placeInput, setPlaceInput] = useState("")
  const [place, setPlace] = useState()
  const [isEditable, setIsEditable] = useState(false)
  const { latitude, longitude, error } = usePosition()

  // no postcode and data exist then load from param
  useEffect(async () => {
    if (!place && data && data.location && data.location.query) {
      const geoResponse = await geocodingClient
        .forwardGeocode({
          query: data.location.query,
          limit: 1,
        })
        .send()

      if (geoResponse && geoResponse.body && geoResponse.body.features[0] && geoResponse.body.features[0].center) {
        const { text, center, place_name } = geoResponse.body.features[0]

        setIsEditable(false)
        setPlace({ text, center, place_name })
        setPlaceInput(shortenInput ? text : place_name)
      }
    }
  }, [place])

  // get browser location and update postcode
  useEffect(async () => {
    if (error || !useBrowserLocation) {
      setIsEditable(true)
      return
    }

    // restrict to only the first load
    if (loadBrowserLocation) {
      if (hasLoadedBrowserLocation) {
        setIsEditable(true)
        return
      }
    }

    if (!latitude) {
      setIsEditable(true)
      return
    }

    if (!error && latitude && longitude && !place && !(data && data.location && data.location.latitude)) {
      const geoResponse = await geocodingClient
        .reverseGeocode({
          query: [longitude, latitude],
          limit: 1,
        })
        .send()

      if (geoResponse && geoResponse.body && geoResponse.body.features[0] && geoResponse.body.features[0].center) {
        const { text, center, place_name } = geoResponse.body.features[0]
        setIsEditable(false)
        setPlace({ text, center, place_name })
        setPlaceInput(shortenInput ? text : place_name)
        if (loadBrowserLocation) {
          loadBrowserLocation(true)
        }
      } else {
        setIsEditable(true)
      }
    }
  }, [latitude, longitude, error, place])

  // track when valid postcode or intentionally no postcode and update data
  useEffect(async () => {
    if (place && place.center) {
      const currLat = data && data.location ? data.location.latitude : 0
      const currLong = data && data.location ? data.location.longitude : 0
      const currRadius =
        data && data.location && data.location.radius ? data.location.radius : GEOLOCATION_DEFAULT_RADIUS
      if (place.center[1] !== currLat || place.center[0] !== currLong) {
        await onChange({
          ...data,
          location: {
            longitude: place.center[0],
            latitude: place.center[1],
            radius: currRadius,
            query: place.place_name,
          },
        })
      }
    } else if (place && place.error) {
      const newdata = { ...data }
      delete newdata.location
      await onChange(newdata)
      setPlace(null)
    }
  }, [place])

  const handleChange = async (e) => {
    const code = e.target.value

    setPlaceInput(code)
    if (code && code.length >= 1) {
      const geoResponse = await geocodingClient
        .forwardGeocode({
          query: code,
          limit: 8,
          proximity: place && place.center ? place.center : null,
        })
        .send()
      if (geoResponse && geoResponse.body && geoResponse.body.features[0]) {
        setPlaceSuggestions(geoResponse.body.features)
      }
    } else if (code.length === 0) {
      setPlace({ error: "removed" })
      setIsEditable(true)
    }
  }

  const handleClear = (e) => {
    setPlaceInput("")
    setPlaceSuggestions(null)
    if (place && place.text) {
      setIsEditable(true)
      setPlace({ error: "removed" })
    }
  }

  const handleSuggestionSelect = (suggestion) => {
    const { text, center, place_name } = suggestion
    setPlace({ text, center, place_name })
    setPlaceInput(shortenInput ? text : place_name)
    setPlaceSuggestions(null)
    setIsEditable(false)
  }

  const inputLocation = useRef(null)

  const handleEditFocus = () => {
    setIsEditable(true)
    setTimeout(() => {
      const input = inputLocation.current
      input.focus()
      input.setSelectionRange(0, input.value.length)
    }, 200) // account for fade
  }

  const handleLooseInputFocus = () => {
    if (place && place.text) {
      setIsEditable(false)
    }

    setTimeout(() => {
      setPlaceSuggestions(null)
    }, 200)
  }

  return (
    <div className={`${styles.GeocodeSelect} ${className || ""} ${isEditable || !shortenInput ? styles.editable : ""}`}>
      <div className={styles.locationInner}>
        <input
          ref={inputLocation}
          type="text"
          value={placeInput}
          onChange={handleChange}
          placeholder={placeholder}
          onBlur={handleLooseInputFocus}
          required={required}
        />
        {shortenInput ? <label onClick={handleEditFocus}>{place ? place.text : ""}</label> : null}
        <button type="button" onClick={handleClear}>
          <img src={closeDark} alt="clear location" />
        </button>
        <ul className={`${styles.suggestions} ${placeSuggestions && placeSuggestions.length ? styles.active : ""}`}>
          {placeSuggestions && placeSuggestions.length
            ? placeSuggestions.map((suggestion) => (
                <li key={suggestion.id} onClick={() => handleSuggestionSelect(suggestion)}>
                  {suggestion.place_name}
                </li>
              ))
            : null}
        </ul>
      </div>
    </div>
  )
}

export default GeocodeSelect
