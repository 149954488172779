// utils/useMediaQuery.js
import { useEffect, useState } from "react"

export default function useMediaQuery(upperSize = 767) {
  const mq = typeof window !== "undefined" ? window.matchMedia(`(max-width: ${upperSize}px)`) : null
  const [isMobile, updateIsMobile] = useState(mq?.matches)

  useEffect(() => {
    updateIsMobile(mq?.matches)
    mq?.addListener(() => updateIsMobile(mq.matches))
    return () => mq?.removeListener(() => updateIsMobile(mq.matches))
  }, [mq])

  return isMobile
}
