import React, { Component } from "react"
import { connect } from "react-redux"
import SiteWrapper from "../../structure/SiteWrapper/SiteWrapper"
import * as styles from "./Login.module.scss"
import * as actions from "@store/actions"
import SimpleIntro from "@components/layouts/global/SimpleIntro/SimpleIntro"
import Button from "@components/objects/Button/Button"
import hoppIconDark from "@images/icons/hoppIconDark.svg"

class Login extends Component {
  state = {
    email: "",
    password: "",
    isProcessing: false,
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  validateLogin = async () => {}

  handleSubmit = async (event) => {
    event.preventDefault()

    this.setState({
      isProcessing: true,
    })

    let result
    if (this.props.hasPartyToCreate) {
      result = await this.props.login(this.state.email, this.state.password, async () => {
        this.props.changeModal("loading")
        await this.props.handleCreateParty(null, true)
      })
    } else {
      result = await this.props.login(this.state.email, this.state.password, this.props.closeModal)
    }

    if (result.message) {
      this.setState({
        isProcessing: false,
      })
      alert(result.message)
    }
  }

  render() {
    return (
      <section className={styles.Login}>
        <SimpleIntro icon={hoppIconDark} title="Welcome back!" description="Please sign in below" />

        <SiteWrapper>
          <form onSubmit={this.handleSubmit}>
            <input
              placeholder="Email address"
              autoFocus
              type="email"
              id="emailLogin"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
              required
            />
            <input
              placeholder="Password"
              type="password"
              id="passwordLogin"
              name="password"
              value={this.state.password}
              onChange={this.handleChange}
              required
            />
            <div className={styles.buttons}>
              <Button
                text="Sign up"
                color="grey"
                design="outline"
                onClick={() => this.props.changeModal(this.props.backStage)}
              />
              <Button
                color="pink"
                text={this.state.isProcessing ? "Loading..." : "Continue"}
                type="submit"
                disabled={this.state.isProcessing}
                className={this.state.isProcessing ? styles.processing : ""}
              />
            </div>

            <p>
              Forgotten your password?{" "}
              <Button design="text" color="dark" onClick={() => this.props.changeModal("forgot")} text="Click here" />
            </p>
          </form>
        </SiteWrapper>
      </section>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password, callback) => dispatch(actions.login(email, password, callback)),
    partyLoadFirst: () => dispatch(actions.partyLoadFirst()),
  }
}

export default connect(null, mapDispatchToProps)(Login)
