import React, { Component } from "react"
import * as styles from "./Hamburger.module.scss"

export default class Hamburger extends Component {
  render() {
    const typeClass = this.props.type === "spin" ? styles.spin : styles.spin
    const active = this.props.active ? styles.isActive : ""
    const classes = `${styles.Hamburger} ${typeClass} ${active}`
    return (
      <button onClick={this.props.clickHandler} className={classes} type="button">
        <span className={styles.hamburgerBox}>
          <span className={styles.hamburgerInner}></span>
        </span>
      </button>
    )
  }
}
