import Cookies from "js-cookie"
import React, { Component } from "react"
import * as styles from "./NewsletterSignUp.module.scss"
import { eventGtag } from "@lib/gtag"
import trackFBEvent from "@lib/trackFBEvent"
import fetchLambda from "@data/fetchLambda"
import lambdas from "@data/lambdas"
import Button from "@components/objects/Button/Button"
import Section from "@components/structure/Section/Section"
import SiteWrapper from "@components/structure/SiteWrapper/SiteWrapper"

const { MAILCHIMP_REQUEST } = lambdas[process.env.NEXT_PUBLIC_ENV]

export default class NewsletterSignUp extends Component {
  state = {
    email: "",
    result: {},
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    document.getElementById("newsletterFooter").reset()

    const result = await fetchLambda(MAILCHIMP_REQUEST, {
      data: {
        email: this.state.email,
      },
    })

    if (result.ok) {
      eventGtag({
        action: "Subscribed",
        params: {
          event_category: "Newsletter",
          event_label: "Footer",
          value: this.state.email,
        },
      })

      trackFBEvent("Subscribe", {
        user_email: this.state.email,
      })
      this.setState({
        result: { msg: "Successfully subscribed to the newsletter", result: "success" },
      })

      Cookies.set("hopp_newsletter", "subscribed", { expires: 365 })
    } else {
      this.setState({
        result: { msg: "Error, try again" },
      })
    }
  }

  render() {
    return (
      <Section className={styles.NewsletterSignUp}>
        <SiteWrapper>
          <h2>{this.props.title}</h2>
          <div
            className={this.state.result.result === "error" ? styles.error : ""}
            dangerouslySetInnerHTML={{ __html: this.state.result.msg }}
          ></div>

          <form id="newsletterFooter" onSubmit={this.handleSubmit}>
            <input type="email" name="email" placeholder="Enter email address" onChange={this.handleChange} />
            <Button text="Sign Up" type="submit" />
          </form>
          <p>{this.props.description}</p>
        </SiteWrapper>
      </Section>
    )
  }
}
