import Cookies from "js-cookie"
import { useEffect } from "react"
import { connect } from "react-redux"
import * as actions from "@store/actions"
import AuthenticationModal from "@components/auth/AuthenticationModal/AuthenticationModal"
import Foundation from "@components/global/Foundation/Foundation"
import Masthead from "@components/global/Masthead/Masthead"

function Layout({
  children,
  enableLock,
  isSiteLocked,
  isAuthenticated,
  lockSite,
  dataLoad,
  options,
  fullscreen = false,
}) {
  useEffect(() => {
    dataLoad(options)
  }, [])

  useEffect(() => {
    if (isAuthenticated === false && !Cookies.get("hopp_newsletter") && localStorage.getItem("hoppViewLimit")) {
      lockSite(true)
    }
  }, [isAuthenticated])

  return (
    <>
      {!fullscreen && <Masthead />}
      <div className="min-h-screen">
        <main>{children}</main>
      </div>
      {/* hidden button - signup modal prompt */}
      {enableLock && isSiteLocked && (
        <AuthenticationModal openOnLoad isLocked defaultStep="limitReached" button={{ design: "hidden" }} />
      )}
      {!fullscreen && <Foundation />}
    </>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isSiteLocked: state.auth.isSiteLocked,
})

const mapDispatchToProps = (dispatch) => ({
  dataLoad: (data) => dispatch(actions.dataLoad(data)),
  lockSite: (lockSite) => dispatch(actions.lockSite(lockSite)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
